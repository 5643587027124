<portal-header></portal-header>
<div class="container-fluid">
	<div class="row my-4">
		<div class="col-xs-12 col-sm-4 col-md-2">
			<div class="row">
				<div class="col-xs-12 col-lg-9">
					<ng-content select="[pageTitleBackButton]"></ng-content>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-sm-8 col-md-10 text-right">
			<ng-content select="[pageTitleText]"></ng-content>
		</div>
	</div>
	<div class="{{ productColor }}-gradient mgn-bottom45 ht2 rounded"></div>
</div>
