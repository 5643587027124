import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

//Override the canvas.toSVG() function to include the layerId
export class ToSVGOverrideService {
	constructor() {}

	public override(obj: any): void {
		obj.toSVG = (function (toSVG) {
			return function () {
				var svgString = toSVG.call(this);
				var domParser = new DOMParser();

				// Handle image case
				if (obj.name === 'image') {
					svgString = svgString.replace('xlink:', '');
				}

				var doc = domParser.parseFromString(svgString, 'image/svg+xml');
				var parentG = doc.querySelector(obj.name);

				if (!parentG) {
					console.warn('Could not find target element:', obj.name);
					console.log('Document structure:', doc.documentElement.innerHTML);
					return svgString; // Return original if we can't modify
				}

				// Set attributes if element was found
				parentG.setAttribute('layerId', obj.layerId?.toString() || '');
				parentG.setAttribute('name', obj.name);
				parentG.setAttribute('isFeedText', obj.isFeedText);
				parentG.setAttribute('isFeedTextImage', obj.isFeedTextImage);
				parentG.setAttribute('isFeedImage', obj.isFeedImage);

				return doc.documentElement.outerHTML;
			};
		})(obj.toSVG);
	}
}
