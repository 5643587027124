import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { DragulaModule } from 'ng2-dragula';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

import { AccordionComponent } from './components/accordion/accordion.component';
import { AdvancedSchedulerComponent } from './components/advanced-scheduler/advanced-scheduler.component';
import { CategoriesFilterPipe } from './components/library-tree/_pipes/categories-filter.pipe';
import { C24ViewComponent } from './components/content-container/content/view-components/c24-view/c24-view.component';
import { CatComponent } from './components/library-tree/library/cat.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ClientNotesComponent } from './components/client-notes/client-notes.component';
import { ClientSwitcherComponent } from './components/client-switcher/client-switcher.component';
import { ContentComponent } from './components/content-container/content/content.component';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { ContentFileComponent } from './components/content-container/content/details/content-file/content-file.component';
import { CustomOrderPipe } from './pipes/order.pipe';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateTimeSelectorComponent } from './components/date-time-selector/date-time-selector.component';
import { DdlrItemComponent } from './components/drag-drop-left-right/ddlr-item.component';
import { DetailsComponent } from './components/content-container/content/details/details.component';
import { DragDropLeftRightComponent } from './components/drag-drop-left-right/drag-drop-left-right.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { DropdownMultiSelectComponent } from './components/dropdown-multi-select/dropdown-multi-select.component';
import { FavoritesFilterPipe, ProductTypeFilterPipe, ProdBuilderFilterPipe } from './components/library-tree/_pipes';
import { FeaturedContentViewComponent } from 'src/app/shared/components/content-container/content/view-components/featured-content-view/featured-content-view.component';
import { FileUploadDropTargetComponent } from './components/file-upload-drop-target/file-upload-drop-target.component';
import { FileUploadModalComponent } from './components/file-upload-modal/file-upload-modal.component';
import { FileUploadProgressBarComponent } from './components/file-upload-progress-bar/file-upload-progress-bar.component';
import { GlobalSearchPipe } from './components/table/pipes/global-search-pipe';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { HeaderAndTitleComponent } from './components/header-and-title/header-and-title.component';
import { HeaderComponent } from './components/header/header.component';
import { HoldCmListViewComponent } from './components/content-container/content/view-components/hold-cm-list-view/hold-cm-list-view.component';
import { HoldPbCardViewComponent } from './components/content-container/content/view-components/hold-pb-card-view/hold-pb-card-view.component';
import { HoldPbListViewComponent } from './components/content-container/content/view-components/hold-pb-list-view/hold-pb-list-view.component';
import { HoursMinutesSecondsPipe } from './pipes';
import { HoverButtonsComponent } from './components/library-tree/hover-buttons.component';
import { LibraryComponent } from './components/library-tree/library/library.component';
import { LibTreeComponent } from './components/library-tree/lib-tree.component';
import { MenuComponent } from './components/header/menu/menu.component';
import { ModalComponent } from './components/modal/modal.component';
import { NewsletterViewComponent } from './components/content-container/content/view-components/newsletter-view/newsletter-view.component';
import { PlaylistDetailsComponent } from './components/playlist-details/playlist-details.component';
import { PlaylistDetailsViewComponent } from './components/content-container/content/view-components/playlist-details-view/playlist-details-view.component';
import { PreviewModalComponent } from 'src/app/shared/components/content-container/content/preview-modal/preview-modal.component';
import { PropFilterPipe } from '../shared/pipes/prop-filter.pipe';
import { RenderPipe } from './components/table/pipes/render-pipe';
import { ScheduledPlayersComponent } from './components/playlist-details/scheduled-players/scheduled-players.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { SelectBoxComponent } from './components/select-box/select-box.component';
import { SortPipe } from './components/table/pipes/sort.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SubCategoriesFilterPipe } from './components/library-tree/_pipes/sub-categories-filter.pipe';
import { FeaturedPlaylistComponent } from './components/library-tree/featured-playlist/featured-playlist.component';
import { FeaturedPlaylistsListViewComponent } from './components/content-container/content/view-components/video-starter-playlists-list-view/video-starter-playlists-list-view.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { StartStopDateTimeComponent } from './components/start-stop-date-time/start-stop-date-time.component';
import { TableComponent } from './components/table/table.component';
import { ToggleButtonsComponent } from './components/toggle-buttons/toggle-buttons.component';
import { VideoCmListViewComponent } from './components/content-container/content/view-components/video-cm-list-view/video-cm-list-view.component';
import { VideoControlsComponent } from './components/content-container/content/video-controls/video-controls.component';
import { VideoPbCardViewComponent } from './components/content-container/content/view-components/video-pb-card-view/video-pb-card-view.component';
import { VideoPbListViewComponent } from './components/content-container/content/view-components/video-pb-list-view/video-pb-list-view.component';
import { VideoPlaylistPreviewerComponent } from './components/video-playlist-previewer/video-playlist-previewer.component';
import { PreviewThumbnailComponent } from './components/content-container/content/preview-thumbnail/preview-thumbnail.component';
import { W24EmployeeOnlyMegaMenuComponent } from './components/header/w24-employee-only-mega-menu/w24-employee-only-mega-menu.component';
import { WrapFnPipe } from './pipes/wrap-fn.pipe';

@NgModule({
	imports: [
		AngularEditorModule,
		AngularMyDatePickerModule,
		CommonModule,
		DragulaModule.forRoot(),
		FileUploadModule,
		FormsModule,
		NgxPaginationModule,
		PerfectScrollbarModule,
		RouterModule,
		NgxTippyModule
	],
	declarations: [
		AccordionComponent,
		AdvancedSchedulerComponent,
		CategoriesFilterPipe,
		C24ViewComponent,
		CatComponent,
		CheckboxComponent,
		ClientNotesComponent,
		ClientSwitcherComponent,
		ContentComponent,
		ContentContainerComponent,
		ContentFileComponent,
		CustomOrderPipe,
		DatePickerComponent,
		DateTimeSelectorComponent,
		DdlrItemComponent,
		DetailsComponent,
		DragDropLeftRightComponent,
		DropdownMenuComponent,
		DropdownMultiSelectComponent,
		FavoritesFilterPipe,
		FeaturedContentViewComponent,
		FileUploadDropTargetComponent,
		FileUploadModalComponent,
		FileUploadProgressBarComponent,
		GlobalSearchPipe,
		HamburgerComponent,
		HeaderAndTitleComponent,
		HeaderComponent,
		HoldCmListViewComponent,
		HoldPbCardViewComponent,
		HoldPbListViewComponent,
		HoursMinutesSecondsPipe,
		HoverButtonsComponent,
		LibraryComponent,
		LibTreeComponent,
		MenuComponent,
		ModalComponent,
		NewsletterViewComponent,
		PlaylistDetailsComponent,
		PlaylistDetailsViewComponent,
		PreviewModalComponent,
		ProdBuilderFilterPipe,
		ProductTypeFilterPipe,
		PropFilterPipe,
		RenderPipe,
		SafeHtmlPipe,
		ScheduledPlayersComponent,
		SearchFilterPipe,
		SelectBoxComponent,
		SortPipe,
		SpinnerComponent,
		SubCategoriesFilterPipe,
		FeaturedPlaylistComponent,
		FeaturedPlaylistsListViewComponent,
		StartStopDateTimeComponent,
		TableComponent,
		ToggleButtonsComponent,
		VideoCmListViewComponent,
		VideoControlsComponent,
		VideoPbCardViewComponent,
		VideoPbListViewComponent,
		VideoPlaylistPreviewerComponent,
		PreviewThumbnailComponent,
		W24EmployeeOnlyMegaMenuComponent,
		WrapFnPipe
	],

	exports: [
		AccordionComponent,
		AdvancedSchedulerComponent,
		AngularEditorModule,
		AngularMyDatePickerModule,
		CategoriesFilterPipe,
		C24ViewComponent,
		CatComponent,
		CheckboxComponent,
		ClientNotesComponent,
		ClientSwitcherComponent,
		CommonModule,
		ContentComponent,
		ContentContainerComponent,
		ContentFileComponent,
		CustomOrderPipe,
		DatePickerComponent,
		DateTimeSelectorComponent,
		DdlrItemComponent,
		DragDropLeftRightComponent,
		DragulaModule,
		DropdownMenuComponent,
		DropdownMultiSelectComponent,
		FavoritesFilterPipe,
		FeaturedContentViewComponent,
		FileUploadDropTargetComponent,
		FileUploadModalComponent,
		FileUploadModule,
		FileUploadProgressBarComponent,
		FormsModule,
		GlobalSearchPipe,
		HamburgerComponent,
		HeaderAndTitleComponent,
		HeaderComponent,
		HoldCmListViewComponent,
		HoldPbCardViewComponent,
		HoldPbListViewComponent,
		HoursMinutesSecondsPipe,
		LibraryComponent,
		LibTreeComponent,
		MenuComponent,
		ModalComponent,
		NewsletterViewComponent,
		NgxPaginationModule,
		PerfectScrollbarModule,
		PlaylistDetailsComponent,
		PlaylistDetailsViewComponent,
		PreviewModalComponent,
		ProdBuilderFilterPipe,
		ProductTypeFilterPipe,
		PropFilterPipe,
		RenderPipe,
		SafeHtmlPipe,
		SearchFilterPipe,
		SelectBoxComponent,
		SortPipe,
		SpinnerComponent,
		SubCategoriesFilterPipe,
		FeaturedPlaylistComponent,
		FeaturedPlaylistsListViewComponent,
		StartStopDateTimeComponent,
		TableComponent,
		ToggleButtonsComponent,
		VideoCmListViewComponent,
		VideoControlsComponent,
		VideoPbCardViewComponent,
		VideoPbListViewComponent,
		VideoPlaylistPreviewerComponent,
		PreviewThumbnailComponent,
		W24EmployeeOnlyMegaMenuComponent,
		NgxTippyModule,
		WrapFnPipe
	]
})
export class SharedModule {}
