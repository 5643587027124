<portal-header></portal-header>
<div class="container-fluid mx-4 mgn-top30 relative">
	<a *ngIf="createEditClientService.mode === 'edit'" (click)="location.back()" class="dark-text absolute topneg21 left0">
		<i class="fa fas fa-chevron-left mgn-right6" aria-hidden="true"></i>
		<span class="ft-size16">Back</span>
	</a>
	<form (keydown.enter)="$event.preventDefault()" class="ft-size16" #form="ngForm" novalidate (ngSubmit)="onSaveClick()">
		<div class="row relative ht40 mgn-bottom17">
			<div class="col-sm-12 pd-left0 mgn-top10">
				<h1>
					<i class="fas fa-user-circle mgn-right12 relative" aria-hidden="true"></i>
					<span class="semi-bold">{{ createEditClientService.mode === 'edit' ? 'Edit' : 'Create New' }} Client</span>
					<span *ngIf="createEditClientService.mode === 'edit'">
						|
						<em>{{ createEditClientService.client.Name }}</em>
					</span>
				</h1>
			</div>
		</div>
		<div class="row green-limeGreen-bg-gradient ht2 mgn-top30 border-radius"></div>
		<div class="row mgn-top30 mgn-bottom40">
			<div class="col-sm-10 col-sm-offset-2">
				<div class="row mgn-bottom30">
					<div class="col-sm-2">
						<h2 class="semi-bold">Client Info</h2>
					</div>
					<div class="col-sm-2">
						<h3 class="semi-bold in-block">Client ID:</h3>
						<span class="ft-size18">{{ appStateService.currentClient.Id }}</span>
					</div>
				</div>
				<div class="row mgn-top20 ft-size16">
					<div class="col-sm-2 col-sm-offset-2">
						<input type="checkbox" id="activeClient" name="activeClient" [(ngModel)]="createEditClientService.client.IsActive" />
						<label class="semi-bold mgn-left6" for="activeClient">Active Client</label>
					</div>
					<div class="col-sm-2">
						<input type="checkbox" id="demo" name="isInternal" [(ngModel)]="createEditClientService.client.IsDemoAccount" />
						<label class="semi-bold mgn-left6" for="demo">Demo Account</label>
					</div>
					<div class="col-sm-4">
						<input type="checkbox" id="internal" name="isDemo" [(ngModel)]="createEditClientService.client.IsInternal" />
						<label class="semi-bold mgn-left6" for="internal">Internal (CSR Managed)</label>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Name:</span>
					</div>
					<div class="col-sm-6 ht40 relative">
						<input
							[ngClass]="{ 'red-border-light': !clientName.valid && !clientName.pristine }"
							name="clientName"
							required
							#clientName="ngModel"
							[(ngModel)]="createEditClientService.client.Name"
							type="text"
						/>
						<i class="fa fa-asterisk ft-size8 red-text-light absolute right0 top0" aria-hidden="true"></i>
						<small class="red-text-light" [hidden]="clientName.valid || (clientName.pristine && !form.submitted)">Client name is required.</small>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Address:</span>
					</div>
					<div class="col-sm-6">
						<input name="address" [(ngModel)]="createEditClientService.client.BillingAddress1" type="text" />
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">City:</label>
					</div>
					<div class="col-sm-2">
						<input name="billingCity" [(ngModel)]="createEditClientService.client.BillingCity" type="text" />
					</div>
					<div class="col-sm-2 text-right">
						<label class="semi-bold">State:</label>
					</div>
					<div class="col-sm-2">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'name'"
							[options]="statesList"
							[scrollbarHidden]="true"
							[selectedOption]="createEditClientService.client?.BillingState"
							(onOptionSelect)="onDropdownSelect($event, 'BillingState', 'code')"
						></portal-dropdown-menu>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Zip:</label>
					</div>
					<div class="col-sm-2 relative">
						<input
							name="billingZip"
							class="{{ invalidZip ? 'border red-border-light' : null }}"
							(blur)="validateZip()"
							(keyup)="onZipKeyup()"
							[(ngModel)]="createEditClientService.client.BillingZip"
							type="text"
						/>
						<em *ngIf="invalidZip" style="right: -90px" class="absolute top12 red-text-light ft-size10">*Not a valid zip code.</em>
					</div>
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Country:</label>
					</div>
					<div class="col-sm-2">
						<input name="billingCountry" [(ngModel)]="createEditClientService.client.BillingCountry" type="text" />
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Phone:</label>
					</div>
					<div class="col-sm-2">
						<input name="mainPhone" [(ngModel)]="createEditClientService.client.MainPhoneNumber" type="text" />
					</div>
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Fax:</label>
					</div>
					<div class="col-sm-2">
						<input name="mainFax" [(ngModel)]="createEditClientService.client.MainFaxNumber" type="text" />
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Website:</label>
					</div>
					<div class="col-sm-2">
						<input name="website" [(ngModel)]="createEditClientService.client.Website" type="text" />
					</div>
					<div class="col-sm-2 text-right">
						<label class="semi-bold">RSSD ID:</label>
					</div>
					<div class="col-sm-2">
						<input name="rssdId" [(ngModel)]="createEditClientService.client.RssdId" type="text" />
						<span>
							* Banks/SLs/CUs ONLY.
							<br />
							<a href="https://www.ffiec.gov/npw" target="_blank">Fed RSSD Id Lookup</a>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row mgn-top30 mgn-bottom40">
			<div class="col-sm-10 col-sm-offset-2">
				<h2 class="semi-bold mgn-bottom30">Client Settings</h2>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">CSR:</label>
					</div>
					<div class="col-sm-6">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'Name'"
							[options]="createEditClientService.csrList$ | async"
							[scrollbarHidden]="true"
							[selectedOption]="currentCsr"
							(onOptionSelect)="onDropdownSelect($event, 'CsrId', 'Id')"
						></portal-dropdown-menu>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Sales Rep:</label>
					</div>
					<div class="col-sm-6">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'Name'"
							[options]="createEditClientService.activeSalesmen$ | async"
							[scrollbarHidden]="true"
							[selectedOption]="currentSalesman"
							(onOptionSelect)="onDropdownSelect($event, 'SalespersonId', 'Id')"
						></portal-dropdown-menu>
					</div>
				</div>
				<div *ngIf="createEditClientService.mode === 'edit'" class="row mgn-top20">
					<div class="col-sm-2 text-right semi-bold">
						<label>Main Contact:</label>
					</div>
					<div class="col-sm-6">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'Name'"
							[options]="createEditClientService.users$ | async"
							[scrollbarHidden]="true"
							[selectedOption]="createEditClientService.mainContact$ | async"
							(onOptionSelect)="onDropdownSelect($event, 'PrimaryContactId', 'Id')"
						></portal-dropdown-menu>
					</div>
				</div>

				<div *ngIf="createEditClientService.mode === 'edit'" class="row mgn-top20">
					<div class="col-sm-2 semi-bold text-right">
						<label>Main Location:</label>
					</div>
					<div class="col-sm-6">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'Name'"
							[options]="createEditClientService.serviceLocations$ | async"
							[scrollbarHidden]="true"
							[selectedOption]="createEditClientService.mainServiceLocation$ | async"
							(onOptionSelect)="onDropdownSelect($event, 'PrimaryLocationId', 'Id')"
						></portal-dropdown-menu>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Root Path:</span>
					</div>
					<div class="col-sm-6 relative ht40">
						<span *ngIf="createEditClientService.mode === 'new'">
							<input
								[ngClass]="{ 'red-border-light': !contentDirectory.valid && !contentDirectory.pristine }"
								name="contentDirectory"
								required
								#contentDirectory="ngModel"
								[ngModel]="rootPath"
								(ngModelChange)="onRootPathChange($event)"
								type="text"
							/>
							<small class="red-text-light" [hidden]="contentDirectory.valid || (contentDirectory.pristine && !form.submitted)">Root path is required.</small>
						</span>
						<button
							*ngIf="createEditClientService.mode === 'new'"
							class="absolute ht36 setWidth25 right16 top2 ln-height16 white-bg"
							(click)="$event.preventDefault()"
							(click)="onGenerateRootPathClick()"
						>
							Generate
						</button>
						<h2 *ngIf="createEditClientService.mode === 'edit'">{{ createEditClientService.client.ContentDirectory }}</h2>
						<i *ngIf="createEditClientService.mode === 'new'" class="fa fa-asterisk ft-size8 red-text-light absolute right0 top0" aria-hidden="true"></i>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right semi-bold">
						<label>Industry:</label>
					</div>
					<div class="col-sm-6 relative">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'Name'"
							[options]="createEditClientService.industryTypes$ | async"
							[scrollbarHidden]="true"
							[selectedOption]="createEditClientService.client?.IndustryType?.Name"
							(onOptionSelect)="onDropdownSelect($event, 'IndustryTypeId', 'Id')"
						></portal-dropdown-menu>
						<i class="fa fa-asterisk ft-size8 red-text-light absolute right0 top0" aria-hidden="true"></i>
					</div>
				</div>
				<div class="row mgn-top20 ft-size16">
					<div class="col-sm-2 semi-bold text-right">
						<label>Allowed IPs:</label>
					</div>
					<div class="col-sm-6">
						<input type="text" name="allowedIps" [(ngModel)]="createEditClientService.client.AllowedIps" />
					</div>
				</div>
				<div class="row mgn-top20 ft-size16">
					<div class="col-sm-2 semi-bold text-right">
						<label>Max Video Playlist Length:</label>
					</div>
					<div class="col-sm-6">
						<input
							type="number"
							name="maxVideoPlaylistLength"
							class="setWidth100 ht40 gray-bg-dark"
							[(ngModel)]="createEditClientService.client.MaxVideoPlaylistLength"
						/>
					</div>
				</div>
				<div class="row mgn-top20 ft-size16">
					<div class="col-sm-2 semi-bold text-right">
						<label>Content Requests Count:</label>
					</div>
					<div class="col-sm-6">
						<input
							type="number"
							name="maxOnHoldContentReq"
							class="setWidth100 ht40 gray-bg-dark"
							[(ngModel)]="createEditClientService.client.MaxOnHoldContentReq"
						/>
					</div>
				</div>

				<div class="row mgn-top20 ft-size16">
					<div class="col-sm-2 semi-bold text-right">
						<label (click)="createEditClientService.client.AllowRecord24 = !createEditClientService.client.AllowRecord24" class="pointer">
							Allow Record24:
						</label>
					</div>
					<div class="col-sm-6">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[type]="'checkbox'"
							[isChecked]="createEditClientService.client.AllowRecord24"
							(checkboxClickNotify)="createEditClientService.client.AllowRecord24 = !createEditClientService.client.AllowRecord24"
						></portal-checkbox>
					</div>
				</div>

				<div class="row mgn-top20 ft-size16">
					<div class="col-sm-2 semi-bold text-right">
						<label (click)="createEditClientService.client.IsBetaTester = !createEditClientService.client.IsBetaTester" class="pointer">Is Beta Tester:</label>
					</div>
					<div class="col-sm-6">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[type]="'checkbox'"
							[isChecked]="createEditClientService.client.IsBetaTester"
							(checkboxClickNotify)="createEditClientService.client.IsBetaTester = !createEditClientService.client.IsBetaTester"
						></portal-checkbox>
					</div>
				</div>

				<div *ngIf="showMfaCheckbox" class="row mgn-top20 ft-size16">
					<div class="col-sm-2 semi-bold text-right">
						<label (click)="createEditClientService.client.Require2fa = !createEditClientService.client.Require2fa" class="pointer">
							Require Multi-Factor Authentication:
						</label>
					</div>
					<div class="col-sm-6">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[type]="'checkbox'"
							[isChecked]="createEditClientService.client.Require2fa"
							(checkboxClickNotify)="createEditClientService.client.Require2fa = !createEditClientService.client.Require2fa"
						></portal-checkbox>
					</div>
				</div>

				<div class="row mgn-top20 ft-size16">
					<div class="col-sm-2 semi-bold text-right">
						<label (click)="createEditClientService.client.AllowAI = !createEditClientService.client.AllowAI" class="pointer">Allow AI:</label>
					</div>
					<div class="col-sm-6">
						<portal-checkbox
							[backgroundColor]="'white-bg'"
							[type]="'checkbox'"
							[isChecked]="createEditClientService.client.AllowAI"
							(checkboxClickNotify)="createEditClientService.client.AllowAI = !createEditClientService.client.AllowAI"
						></portal-checkbox>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="createEditClientService.mode === 'new'" class="row mgn-top30 mgn-bottom40">
			<div class="col-sm-10 col-sm-offset-2">
				<h2 class="semi-bold mgn-bottom30">Contracts</h2>
				<div class="row mgn-bottom15">
					<div class="col-sm-offset-2 col-sm-3">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'ProductName'"
							[options]="createEditClientService.productTypes$ | async"
							[scrollbarHidden]="true"
							[selectedOption]="createEditClientService.productType.ProductName"
							(onOptionSelect)="onProductTypeSelect($event)"
						></portal-dropdown-menu>
					</div>
					<div class="col-sm-3">
						<button
							[disabled]="!createEditClientService.productType.Id"
							class="{{ !createEditClientService.productType.Id ? 'setOpacityLow' : null }} border-radius limeGreen-bg white-text setWidth50"
							(click)="$event.preventDefault()"
							(click)="onAddContractClick()"
						>
							Add contract
						</button>
						<i *ngIf="createEditClientService.mode === 'new'" class="fa fa-asterisk ft-size8 red-text-light absolute right0 top0" aria-hidden="true"></i>
					</div>
				</div>
				<div *ngFor="let contract of createEditClientService.contracts; let i = index" class="row ft-size16">
					<div class="col-sm-6 col-sm-offset-2">
						<cec-contract
							[activeSalesmen]="createEditClientService.activeSalesmen$ | async"
							[contract]="contract"
							[index]="i"
							(inputChangeNotify)="formValidationService.allContractsValidCheck($event)"
							(onRemoveContractNotify)="onRemoveContractClick($event)"
						></cec-contract>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="createEditClientService.mode === 'new'" class="row mgn-top30 mgn-bottom40">
			<div class="col-sm-10 col-sm-offset-2">
				<h2 class="semi-bold mgn-bottom30">Initial Location</h2>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Name:</span>
					</div>
					<div class="col-sm-6 relative">
						<input
							type="text"
							[ngClass]="{ 'red-border-light': !initialLocationName.valid && !initialLocationName.pristine }"
							name="initialLocationName"
							required
							#initialLocationName="ngModel"
							[(ngModel)]="createEditClientService.initialServiceLocation.Name"
						/>
						<small class="red-text-light" [hidden]="initialLocationName.valid || (initialLocationName.pristine && !form.submitted)">
							Location name is required.
						</small>
						<br />
						<button class="border-radius gray-bg setWidth50 mgn-top10" (click)="$event.preventDefault()" (click)="onCopyAddressClick()">
							Copy Address From Client
						</button>
						<i class="fa fa-asterisk ft-size8 red-text-light absolute right0 top0" aria-hidden="true"></i>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<span class="semi-bold">Address:</span>
					</div>
					<div class="col-sm-6">
						<input type="text" name="initialLocationAddress" [(ngModel)]="createEditClientService.initialServiceLocation.Address1" />
					</div>
				</div>

				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">City:</label>
					</div>
					<div class="col-sm-2">
						<input type="text" name="initialLocationCity" [(ngModel)]="createEditClientService.initialServiceLocation.City" />
					</div>
					<div class="col-sm-2 text-right">
						<label class="semi-bold">State:</label>
					</div>
					<div class="col-sm-2 ht40">
						<portal-dropdown-menu
							[backgroundColor]="'gray-bg-dark'"
							[height]="'ht40'"
							[key]="'name'"
							[options]="statesList"
							[scrollbarHidden]="true"
							[selectedOption]="createEditClientService.initialServiceLocation?.State ? createEditClientService.initialServiceLocation.State : null"
						></portal-dropdown-menu>
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Zip:</label>
					</div>
					<div class="col-sm-2">
						<input type="text" name="initialLocationZip" [(ngModel)]="createEditClientService.initialServiceLocation.Zip" />
					</div>
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Country:</label>
					</div>
					<div class="col-sm-2">
						<input type="text" name="initialLocationCountry" [(ngModel)]="createEditClientService.initialServiceLocation.Country" />
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Phone:</label>
					</div>
					<div class="col-sm-2">
						<input type="text" name="initialLocationPhone" [(ngModel)]="createEditClientService.initialServiceLocation.Phone" />
					</div>
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Fax:</label>
					</div>
					<div class="col-sm-2">
						<input type="text" name="initialLocationFax" [(ngModel)]="createEditClientService.initialServiceLocation.Fax" />
					</div>
				</div>
				<div class="row mgn-top20">
					<div class="col-sm-2 text-right">
						<label class="semi-bold">Phone System:</label>
					</div>
					<div class="col-sm-6">
						<input type="text" name="initialLocationPhoneSystem" [(ngModel)]="createEditClientService.initialServiceLocation.PhoneSystem" />
					</div>
				</div>
			</div>
		</div>

		<div class="row mgn-top50 mgn-bottom50">
			<div class="col-sm-10 col-sm-offset-2">
				<div class="row">
					<div class="col-sm-8">
						<div class="row">
							<div class="col-sm-3 col-sm-offset-6">
								<button class="gray-bg" [routerLink]="['/crm/client-details/locations']">Cancel</button>
							</div>
							<div class="col-sm-3">
								<button [disabled]="!form.valid || !formValidationService.allContractsValid || invalidZip" type="submit" class="limeGreen-bg white-text">
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
