<div class="container-fluid pd-bottom15 pd-top15 {{ contract?.bgColor }} border-radius shadow">
	<!--READ MODE-->
	<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="onEditSaveClick(contract.viewState === 'readMode' ? 'edit' : 'save')">
		<div class="row mgn-bottom15 border-radius">
			<div class="col-sm-6">
				<h3 class="semi-bold">
					{{ contract?.productType }} Contract
					<span *ngIf="contract.Id">, Renews {{ contract.renewDateView }}</span>
				</h3>
				<h3 class="">
					STATUS:
					<span class="{{ contract.textColor }}">{{ contractStatus }}</span>
				</h3>
			</div>
			<div class="col-sm-3">
				<a href="{{ downloadUrl }}" target="_new">Download this contract</a>
			</div>
			<div class="col-sm-1">
				<button
					*ngIf="editDeleteContractEnabled()"
					type="button"
					class="white-bg"
					(click)="onDeleteCancelClick(contract.viewState === 'readMode' ? 'delete' : 'cancel')"
				>
					<i class="fa {{ contract.viewState === 'readMode' ? 'fa-trash-alt' : 'fa-ban' }} mgn-right8" aria-hidden="true"></i>
					{{ contract.viewState === 'readMode' ? 'Delete' : 'Cancel' }}
				</button>
			</div>
			<div class="col-sm-1">
				<button type="button" (click)="onUploadClick()" class="white-bg">
					<i class="far fa-file-pdf mgn-right8" aria-hidden="true"></i>
					Upload
				</button>
				<input type="file" #fileInput accept=".pdf" style="display: none" (change)="onFileChange($event)" />
			</div>
			<div class="col-sm-1">
				<button
					[disabled]="!isFormValid"
					[ngClass]="!isFormValid ? 'setOpacityLow' : ''"
					class="{{ contract.viewState === 'readMode' ? 'white-bg' : 'limeGreen-bg white-text' }} border-radius setWidth100"
				>
					<i class="fas {{ contract.viewState === 'readMode' ? 'fa-pencil-alt' : 'fa-save' }} mgn-right8" aria-hidden="true"></i>
					{{ contract.viewState === 'readMode' ? 'Edit' : 'Save' }}
				</button>
			</div>
		</div>
		<div *ngIf="!contract.Id" class="row mgn-bottom15">
			<div class="col-sm-offset-6 col-sm-6 text-right">
				<span class="semi-bold red-text-light"><em>*This contract will be saved ONLY when you click save!*</em></span>
			</div>
		</div>
		<div *ngIf="contract.viewState === 'readMode'" class="row ft-size15">
			<div class="col-sm-3">
				<span class="semi-bold">Salesperson:</span>
				<span>{{ contract.salespersonName }}</span>
				<br />
				<span class="semi-bold">Term:</span>
				<span>{{ contract.term }}</span>
				<br />
				<span class="semi-bold">Sale Date:</span>
				<span>{{ contract.saleDateView }}</span>
				<br />
				<span class="semi-bold">Renew Date:</span>
				<span>{{ contract.renewDateView }}</span>
				<br />
				<span class="semi-bold">Renewed:</span>
				<span>{{ contract.IsRenewed }}</span>
				<br />
				<span class="semi-bold">Contract ID:</span>
				<span>{{ contract.Id }}</span>
			</div>
			<div class="col-sm-3">
				<span class="semi-bold">Locations:</span>
				<span>{{ contract.NumLocations }}</span>
				<br />
				<span class="semi-bold">Total Retail:</span>
				<span>{{ contract.TotalValue }}</span>
				<br />
				<span class="semi-bold">Monthly (Main Location):</span>
				<span>{{ contract.MainLocMonthly }}</span>
				<br />
				<span class="semi-bold">Monthly (Additional Locations):</span>
				<span>{{ contract.DupLocMonthly }}</span>
			</div>
			<div class="col-sm-3">
				<span class="semi-bold">Canceled Early:</span>
				<span>{{ contract.IsEarlyCancel }}</span>
				<br />
				<span class="semi-bold">Cancel Date:</span>
				<span>{{ contract.earlyCancelDateView }}</span>
				<br />
				<span class="semi-bold">Reason:</span>
				<span>{{ contract.EarlyCancelReason }}</span>
				<br />
				<span class="semi-bold">Exempt From Stats:</span>
				<span>{{ contract.IsExemptFromStats }}</span>
				<br />
				<span class="semi-bold">Reason:</span>
				<span>{{ contract.ExemptFromStatsReason }}</span>
				<br />
				<span class="semi-bold">Expired:</span>
				{{ contract.IsFulfilledContractCancellation }}
				<span></span>
				<br />
				<span class="semi-bold">Reason:</span>
				<span>{{ contract.FulfilledContractCancellationReason }}</span>
			</div>
			<div class="col-sm-3">
				<span class="semi-bold">Notes:</span>
				<br />
				<span>{{ contract.Notes }}</span>
			</div>
		</div>

		<!--EDIT MODE-->

		<div *ngIf="contract.viewState === 'editMode'" class="ft-size15">
			<div class="row">
				<div class="col-sm-4">
					<div class="row mgn-bottom15">
						<div class="col-sm-3">
							<span class="semi-bold">Salesperson:</span>
						</div>
						<div class="col-sm-9 relative">
							<portal-dropdown-menu
								[backgroundColor]="'white-bg'"
								[height]="'ht40'"
								[key]="'Name'"
								[options]="activeSalesmen$ | async"
								[selectedOption]="contract.salespersonName"
								(onOptionSelect)="onDropdownSelect($event, 'SalespersonId', 'salespersonName')"
							></portal-dropdown-menu>
							<i class="fa fa-asterisk red-text ft-size7 absolute top2 right0" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row mgn-bottom15">
						<div class="col-sm-3">
							<span class="semi-bold">Term:</span>
						</div>
						<div class="col-sm-9 relative">
							<portal-dropdown-menu
								[backgroundColor]="'white-bg'"
								[height]="'ht40'"
								[key]="'name'"
								[options]="contractTermsService.contractTerms"
								[selectedOption]="contract.term"
								(onOptionSelect)="onDropdownSelect($event, 'ContractTermId', 'term')"
							></portal-dropdown-menu>
							<i class="fa fa-asterisk red-text ft-size7 absolute top2 right0" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row mgn-bottom15">
						<div class="col-sm-3">
							<span class="semi-bold">Sale Date:</span>
						</div>
						<div class="col-sm-9 relative">
							<portal-date-time-selector
								[backgroundColor]="'white-bg'"
								[dateModel]="contract.SaleDate"
								(dateChanged)="onDateChanged($event, 'SaleDate')"
							></portal-date-time-selector>
							<i class="fa fa-asterisk red-text ft-size7 absolute top2 right0" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row mgn-bottom15">
						<div class="col-sm-3">
							<span class="semi-bold">Renew Date:</span>
						</div>
						<div class="col-sm-9 relative">
							<portal-date-time-selector
								[backgroundColor]="'white-bg'"
								[dateModel]="contract.RenewDate"
								(dateChanged)="onDateChanged($event, 'RenewDate')"
							></portal-date-time-selector>
							<i class="fa fa-asterisk red-text ft-size7 absolute top2 right0" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row mgn-bottom15">
						<div class="col-sm-3">
							<span class="semi-bold">Contract ID:</span>
						</div>
						<div class="col-sm-9">
							<span>{{ contract.Id }}</span>
						</div>
					</div>
				</div>
				<div class="col-sm-4">
					<div class="row mgn-bottom15">
						<div class="col-sm-7">
							<span class="semi-bold">Locations:</span>
						</div>
						<div class="col-sm-5 relative">
							<input
								[ngClass]="{ 'red-border-light': !locations.valid && !locations.pristine }"
								required
								#locations="ngModel"
								id="locations"
								name="locations"
								class="setWidth100 ht40 white-bg"
								type="number"
								min="0"
								oninput="validity.valid||(value='');"
								[ngModel]="contract.NumLocations"
								(ngModelChange)="onInputChange($event, 'NumLocations')"
							/>
							<i class="fa fa-asterisk red-text ft-size7 absolute top2 right0" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row mgn-bottom15">
						<div class="col-sm-7">
							<span class="semi-bold">Total Retail:</span>
						</div>
						<div class="col-sm-5 relative">
							<input
								[ngClass]="{ 'red-border-light': !totalValue.valid && !totalValue.pristine }"
								required
								#totalValue="ngModel"
								id="totalValue"
								name="totalValue"
								class="setWidth100 ht40 white-bg"
								type="number"
								min="0"
								oninput="validity.valid||(value='');"
								[ngModel]="contract.TotalValue"
								(ngModelChange)="onInputChange($event, 'TotalValue')"
							/>
							<i class="fa fa-asterisk red-text ft-size7 absolute top2 right0" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row mgn-bottom15">
						<div class="col-sm-7">
							<span class="semi-bold">Monthly (Main Location):</span>
						</div>
						<div class="col-sm-5 relative">
							<input
								[ngClass]="{ 'red-border-light': !mainLocMonthly.valid && !mainLocMonthly.pristine }"
								required
								#mainLocMonthly="ngModel"
								id="mainLocMonthly"
								name="mainLocMonthly"
								class="setWidth100 ht40 white-bg"
								type="number"
								min="0"
								oninput="validity.valid||(value='');"
								[ngModel]="contract.MainLocMonthly"
								(ngModelChange)="onInputChange($event, 'MainLocMonthly')"
							/>
							<i class="fa fa-asterisk red-text ft-size7 absolute top2 right0" aria-hidden="true"></i>
						</div>
					</div>
					<div class="row mgn-bottom51">
						<div class="col-sm-7">
							<span class="semi-bold">Monthly (Additional Locations):</span>
						</div>
						<div class="col-sm-5 relative">
							<input
								[ngClass]="{ 'red-border-light': !dupLocMonthly.valid && !dupLocMonthly.pristine }"
								required
								#dupLocMonthly="ngModel"
								id="dupLocMonthly"
								name="dupLocMonthly"
								class="setWidth100 ht40 white-bg"
								type="number"
								min="0"
								oninput="validity.valid||(value='');"
								[ngModel]="contract.DupLocMonthly"
								(ngModelChange)="onInputChange($event, 'DupLocMonthly')"
							/>
							<i class="fa fa-asterisk red-text ft-size7 absolute top2 right0" aria-hidden="true"></i>
						</div>
					</div>
				</div>
				<div class="col-sm-4">
					<span class="semi-bold">Notes:</span>
					<br />
					<textarea rows="4" class="setWidth100 white-bg" name="notes" [ngModel]="contract.Notes" (ngModelChange)="onInputChange($event, 'Notes')"></textarea>
				</div>
			</div>
			<div class="row mgn-top30 mgn-bottom30">
				<div class="col-sm-4">
					<h2>Renewal Information</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-4">
					<div class="row mgn-bottom5">
						<div class="col-sm-4">
							<label class="semi-bold pointer" for="renewed">Renewed:</label>
						</div>
						<div class="col-sm-8">
							<input
								class="ft-size16"
								id="renewed"
								type="checkbox"
								name="isRenewed"
								[checked]="contract.IsRenewed"
								(change)="onInputChange($event, 'IsRenewed')"
							/>
						</div>
					</div>
					<div class="row mgn-bottom5">
						<div class="col-sm-4">
							<label class="semi-bold pointer" for="earlyCancel">Canceled Early:</label>
						</div>
						<div class="col-sm-8">
							<input
								class="ft-size16"
								id="earlyCancel"
								type="checkbox"
								name="isEarlyCancel"
								[checked]="contract.IsEarlyCancel"
								(change)="onInputChange($event, 'IsEarlyCancel')"
							/>
						</div>
					</div>
					<div class="row mgn-bottom5">
						<div class="col-sm-4">
							<label class="semi-bold pointer" for="fullyExpired">Fully Expired:</label>
						</div>
						<div class="col-sm-8">
							<input
								class="ft-size16"
								id="fullyExpired"
								name="isFulfilledContractCancellation"
								type="checkbox"
								[checked]="contract.IsFulfilledContractCancellation"
								(change)="onInputChange($event, 'IsFulfilledContractCancellation')"
							/>
						</div>
					</div>
					<div *ngIf="contract.IsFulfilledContractCancellation" class="row mgn-bottom5">
						<div class="col-sm-4">
							<span class="semi-bold">Reason:</span>
						</div>
						<div class="col-sm-8">
							<textarea
								rows="4"
								class="setWidth100 white-bg"
								name="fulfilledContractCancellationReason"
								[ngModel]="contract.FulfilledContractCancellationReason"
								(ngModelChange)="onInputChange($event, 'FulfilledContractCancellationReason')"
							></textarea>
						</div>
					</div>
					<div *ngIf="contract.IsEarlyCancel" class="row mgn-bottom5">
						<div class="col-sm-4">
							<span class="semi-bold">Cancel Date:</span>
						</div>
						<div class="col-sm-8">
							<portal-date-time-selector
								[backgroundColor]="'white-bg'"
								[dateModel]="contract.EarlyCancelDate"
								(dateChanged)="onDateChanged($event, 'EarlyCancelDate')"
							></portal-date-time-selector>
						</div>
					</div>
					<div *ngIf="contract.IsEarlyCancel" class="row mgn-bottom5">
						<div class="col-sm-4">
							<span class="semi-bold">Cancel Reason:</span>
						</div>
						<div class="col-sm-8">
							<textarea
								rows="4"
								class="setWidth100 white-bg"
								name="earlyCancelReason"
								[ngModel]="contract.EarlyCancelReason"
								(ngModelChange)="onInputChange($event, 'EarlyCancelReason')"
							></textarea>
						</div>
					</div>
				</div>
				<div class="col-sm-4">
					<div class="row mgn-bottom5">
						<div class="col-sm-6">
							<label class="semi-bold pointer" for="exemptFromStats">Exempt From Renewal Stats:</label>
						</div>
						<div class="col-sm-6">
							<input
								class="ft-size16"
								id="exemptFromStats"
								type="checkbox"
								name="exemptFromStats"
								[ngModel]="contract.IsExemptFromStats"
								(ngModelChange)="onInputChange($event, 'IsExemptFromStats')"
							/>
						</div>
					</div>
					<div *ngIf="contract.IsExemptFromStats" class="row mgn-bottom5">
						<div class="col-sm-6">
							<span class="semi-bold">Reason:</span>
						</div>
						<div class="col-sm-6">
							<textarea
								rows="4"
								class="setWidth100 white-bg"
								[ngModel]="contract.ExemptFromStatsReason"
								name="exemptFromStatsReason"
								(ngModelChange)="onInputChange($event, 'ExemptFromStatsReason')"
							></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
