import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';

import { Create24Area } from 'src/app/user-tools/create-24/_models/create-24-state.enum';
import { Create24Service } from 'src/app/user-tools/create-24/create-24.service';
import { environment } from '../../../../environments/environment';
import { AppStateService, FileUploadService, RotateImageService, SecurityService, UtilitiesService } from '../../../core/services';
import { ContentLibraries, ContentLibraryCategories, ContentVoiceTalents } from '../../api-models/content';
import { FileUploadStateService } from '../../services';

@Injectable({
	providedIn: 'root'
})
export class FileUploadModalService {
	public isStillImage: boolean;
	public voiceTalentList: ContentVoiceTalents[] = [];

	constructor(
		private appStateService: AppStateService,
		private create24Service: Create24Service,
		private fileUploadService: FileUploadService,
		private fileUploadStateService: FileUploadStateService,
		private httpClient: HttpClient,
		private rotateImageService: RotateImageService,
		private securityService: SecurityService,
		private utilService: UtilitiesService
	) {}

	onVoiceTalentSelect(voiceTalent: ContentVoiceTalents): void {
		this.fileUploadStateService.uploadData.VoiceTalentId = voiceTalent.Id;
		this.fileUploadStateService.voiceTalent = voiceTalent;
	}

	public modalInit(aiVoiceTalent?: ContentVoiceTalents): void {
		if (aiVoiceTalent) {
			this.fileUploadStateService.uploadData.VoiceTalentId = aiVoiceTalent.Id;
			this.fileUploadStateService.voiceTalent = aiVoiceTalent;
			return;
		}
		if (this.appStateService.product.Route === 'hold') {
			this.fileUploadStateService.uploadData.VoiceTalentId = 1;
			this.fileUploadStateService.voiceTalent = {
				TalentGender: 'Male',
				TalentName: 'Generic Male',
				name: 'Generic Male'
			};
		}
	}

	public onCancelClick(): void {
		this.fileUploadStateService.resetSteps();
	}

	public onUploadClick(clipId?: number): void {
		if (!this.fileUploadStateService.counterpartOrReplace) {
			this.fileUploadStateService.advance();
			this.getCategoriesByTargetLibraryId(this.fileUploadStateService.uploadData.TargetLibraryId);
			if (this.appStateService.product.Route !== 'hold' && this.isStillImage) {
				this.fileUploadService.uploadFileViaGeneralUploadApi(
					this.utilService.dataURLtoFile(this.rotateImageService.imageSrc, this.fileUploadStateService.file.name),
					this.fileUploadStateService.uploadData,
					clipId,
					'ContentRequestCompletedFile'
				);
			} else {
				this.fileUploadService.uploadFileViaGeneralUploadApi(
					this.fileUploadStateService.file,
					this.fileUploadStateService.uploadData,
					clipId,
					'ContentRequestCompletedFile'
				);
			}

			return;
		}
		if (this.fileUploadStateService.counterpartOrReplace === 'counterpart') {
			this.fileUploadStateService.advance();
			this.fileUploadService.uploadCounterpartFile(this.fileUploadStateService.counterpartUploadData(), clipId);
		}
	}

	public getLibraries$(c24SaveNewSidebar?: boolean): Observable<ContentLibraries[]> {
		return this.getLibrariesObserv$(c24SaveNewSidebar).pipe(
			map((libraries: ContentLibraries[]) => {
				libraries = libraries.map((library) => {
					library.name = library.LibraryName;
					return library;
				});
				const filteredLibraries = this.securityService.filterLibraries(libraries, this.appStateService.activeFeatureArea);
				return this.utilService.sortItems(filteredLibraries, 'LibraryName');
			})
		);
	}

	private getLibrariesObserv$(saveFromC24?: boolean): Observable<ContentLibraries[]> {
		const { currentClient, currentUser, isDevMode } = this.appStateService;
		const productId: number = this.appStateService.product.Id;

		//If user is employee, returns all libraries regardless of userId
		const c24PlacedImages$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=8&clientId=${currentClient.Id}&userId=${currentUser.UserId}&contentLibraryTypeId=7&allowCreate24=true`
		);
		const c24CustomAndGlobalVideo$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${currentClient.Id}&userId=${currentUser.UserId}&contentLibraryTypeId=6&allowCreate24=true`
		);
		const c24CustomAndGlobalStill$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${currentClient.Id}&userId=${currentUser.UserId}&contentLibraryTypeId=5&allowCreate24=true`
		);
		const c24SampleTemplates$: Observable<ContentLibraries[]> = this.httpClient
			.get<ContentLibraries>(`${environment.contentUrl}ContentLibraries/${isDevMode ? 1000678 : 1000717}`)
			.pipe(map((lib) => [lib]));
		const c24ClientCustomLibrary$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${currentClient.Id}&userId=${currentUser.UserId}&contentLibraryTypeId=3&allowCreate24=true`
		);
		const c24Sidebar$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${currentClient.Id}&userId=${currentUser.UserId}&contentLibraryTypeId=12&allowCreate24=true`
		);
		const c24SidebarBg$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${currentClient.Id}&userId=${currentUser.UserId}&contentLibraryTypeId=13&allowCreate24=true`
		);
		const default$: Observable<ContentLibraries[]> = this.httpClient.get<ContentLibraries[]>(
			`${environment.contentUrl}ContentLibraries/AvailableLibraries?productId=${productId}&clientId=${currentClient.Id}&userId=${currentUser.UserId}`
		);
		switch (this.create24Service.c24ActiveArea) {
			case Create24Area.NEW_VIDEO:
				return c24CustomAndGlobalVideo$;
			case Create24Area.NEW_IMAGE:
				return c24CustomAndGlobalStill$;
			case Create24Area.NEW_SIDEBAR:
			case Create24Area.SIDEBAR_TEMPLATE:
				//Return c24 sidebar libraries if saving c24 project. Return sidebar bg libraries if uploading from content browser
				return saveFromC24 ? c24Sidebar$ : c24SidebarBg$;
			case Create24Area.EDIT_PROJECT_SIDEBAR:
				return c24Sidebar$;
			case Create24Area.IMAGE_LAYER_CREATE:
			case Create24Area.IMAGE_LAYER_REPLACE:
				return c24PlacedImages$;
			case Create24Area.VIDEO_TEMPLATE:
				return c24SampleTemplates$;
			case Create24Area.EDIT_PROJECT:
				return c24ClientCustomLibrary$;
			default:
				return default$;
		}
	}

	public getSelectedLibrary(libraryId: number): Observable<ContentLibraries> {
		return this.httpClient.get<ContentLibraries>(`${environment.contentUrl}ContentLibraries/${libraryId}`);
	}

	public getCategoriesByTargetLibraryId(targetLibraryId: number): void {
		this.httpClient
			.get(`${environment.contentUrl}ContentLibraries/${targetLibraryId}/Categories`)
			.pipe(
				map((categories: ContentLibraryCategories[]) => {
					const newContentCategory: ContentLibraryCategories = categories.find((category) => category.CategoryName === 'New Content');
					if (newContentCategory) {
						newContentCategory.IsDeleted = false;
						this.httpClient.put(`${environment.contentUrl}ContentLibraryCategories/${newContentCategory.Id}`, newContentCategory).subscribe();
					}
				})
			)
			.subscribe();
	}

	public getVoiceTalentList(): void {
		if (this.voiceTalentList?.length < 1) {
			this.httpClient
				.get<ContentVoiceTalents[]>(`${environment.contentUrl}ContentVoiceTalents`)
				.pipe(
					publishReplay(1),
					refCount(),
					map((res) => {
						const order = [1, 2, 1000004, 1000000, 1000002, 1000005, 1000001, 1000003];
						return this.utilService.mapOrder(res, order, 'Id');
					})
				)
				.subscribe((voiceTalentList: ContentVoiceTalents[]) => {
					this.voiceTalentList = voiceTalentList;
					//Make sure to only add "All" a single time
					if (this.voiceTalentList[0] && this.voiceTalentList[0].TalentName !== 'All') {
						this.voiceTalentList.unshift({ TalentName: 'All', Id: 0, TalentGender: 'N/A', name: 'All' });
					}
				});
		}
	}
}
