<div class="border-radius white-bg pd30 center setZ relative">
	<div class="row">
		<div class="col-sm-12 text-center">
			<span class="ft-size26 dark-text absolute right15 pointer top0" (click)="fileUploadModalService.onCancelClick()">
				<i class="fa fa-times" aria-hidden="true"></i>
			</span>
			<h1 class="in-block semi-bold">File Uploader</h1>
			<h3 class="mgn-top12">
				<em>{{ fileUploadStateService.file?.name }}</em>
			</h3>
			<h4 *ngIf="fileUploadStateService.currentStep().contentBreadCrumbs" class="mgn-top15 ft-size12">
				<em>
					{{ fileUploadStateService.selectedContent.breadcrumbs[0] }} > {{ fileUploadStateService.selectedContent.breadcrumbs[1] }}
					<span *ngIf="!fileUploadStateService.selectedContent.breadcrumbs[2]">> {{ fileUploadStateService.selectedContent.Title }}</span>
					<span *ngIf="fileUploadStateService.selectedContent.breadcrumbs[2]">></span>
					{{ fileUploadStateService.selectedContent.breadcrumbs[2] }} > {{ fileUploadStateService.selectedContent.Title }}
				</em>
			</h4>
		</div>
	</div>

	<form (keydown.enter)="$event.preventDefault()" #form="ngForm" novalidate (ngSubmit)="fileUploadModalService.onUploadClick()">
		<div *ngIf="fileUploadStateService.currentStep().title" class="row mgn-top56 text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<input
					[ngClass]="{ 'red-border-light': !title.valid && !title.pristine }"
					type="text"
					name="title"
					required
					#title="ngModel"
					placeholder="Please enter a title"
					[(ngModel)]="fileUploadStateService.uploadData.Title"
				/>
				<small class="red-text-light" [hidden]="title.valid || (title.pristine && !form.submitted)">Title is required.</small>
				<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top0" aria-hidden="true"></i>
			</div>
		</div>
		<div *ngIf="fileUploadStateService.currentStep().script" class="row text-left mgn-top25">
			<div class="col-sm-8 col-sm-offset-2">
				<textarea
					type="text"
					name="script"
					#script="ngModel"
					rows="5"
					placeholder="Please enter a script"
					[(ngModel)]="fileUploadStateService.uploadData.Text"
				></textarea>
			</div>
		</div>

		<div *ngIf="fileUploadStateService.currentStep().selectLibrary" class="row mgn-top30 text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<h3>Select a library for this file:</h3>
				<portal-dropdown-menu
					[backgroundColor]="'gray-bg-dark'"
					[height]="'ht40'"
					[key]="'LibraryName'"
					[options]="libraries"
					[selectedOption]="selectedLibrary?.LibraryName"
					(onOptionSelect)="onLibrarySelect($event)"
				></portal-dropdown-menu>
				<i class="fa fa-asterisk red-text-light ft-size8 absolute rightneg3 top25" aria-hidden="true"></i>
			</div>
		</div>
		<div *ngIf="fileUploadStateService.currentStep().selectLibrary" class="row text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<span><em>Your new file will be placed in a category folder called "New Content."</em></span>
				<br />
			</div>
		</div>

		<div *ngIf="fileUploadStateService.currentStep().selectVoiceTalent" class="row mgn-top30 text-left">
			<div class="col-sm-8 col-sm-offset-2">
				<h3>Please select a voice talent label</h3>
				<ng-container *ngIf="fileUploadModalService.voiceTalentList?.length > 0">
					<portal-dropdown-menu
						[backgroundColor]="'gray-bg-dark'"
						[errorVisible]="error.voiceTalentErrorVisible"
						[height]="'ht40'"
						[options]="fileUploadModalService.voiceTalentList"
						[key]="'TalentName'"
						[selectedOption]="fileUploadModalService.voiceTalentList[0].TalentName"
						(onOptionSelect)="fileUploadModalService.onVoiceTalentSelect($event)"
					></portal-dropdown-menu>
				</ng-container>
			</div>
		</div>

		<div *ngIf="fileUploadStateService.currentStep().chooseResizeMethod" class="row mgn-top30">
			<div class="col-sm-8 col-sm-offset-2 text-left">
				<h3 class="mgn-bottom15">Choose a resize method:</h3>
				<div class="row text-center">
					<div class="col-sm-4">
						<input type="radio" id="stretch" [checked]="true" name="resize" (click)="fileUploadStateService.uploadData.ResizeMethod = 'STRETCH'" />
						<label for="stretch">
							Stretch
							<br />
							<img src="/assets/stretch.png" />
						</label>
					</div>
					<div class="col-sm-4">
						<input type="radio" id="center" name="resize" (click)="fileUploadStateService.uploadData.ResizeMethod = 'CENTER'" />
						<label for="center">
							Center
							<br />
							<img src="/assets/center.png" />
						</label>
					</div>
					<div class="col-sm-4">
						<input type="radio" id="letterbox" name="resize" (click)="fileUploadStateService.uploadData.ResizeMethod = 'LETTERBOX'" />
						<label for="letterbox">
							Letterbox
							<br />
							<img src="/assets/letterbox.png" />
						</label>
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="appStateService.product.Route !== 'hold' && fileUploadModalService.isStillImage">
			<div class="row mgn-top30 text-left">
				<div class="col-sm-8 col-sm-offset-2 text-left">
					<h3 class="mgn-bottom15 in-block">Rotate your image (if necessary):</h3>
					<span
						class="pointer mgn-left10"
						ngxTippy
						tippyName="{{ utilService.guid | wrapFn }}"
						[tippyProps]="{ appendTo: 'parent' }"
						data-tippy-content="The orientation of your photo here will be the same as on your TV. Correct if necessary with the buttons below."
					>
						<i class="far fa-question-circle" aria-hidden="true"></i>
					</span>
					<portal-spinner
						*ngIf="rotateImageService.processing"
						style="right: 41%"
						class="absolute top13"
						[width]="'wdthpx28'"
						[height]="'htpx28'"
					></portal-spinner>
					<img style="width: 100%" src="{{ rotateImageService.imageSrc }}" />
				</div>
			</div>

			<div class="row mgn-top30">
				<div class="col-xs-3 col-xs-offset-3">
					<button type="button" class="white-bg" (click)="rotateImageService.onRotateClick('left')">
						<i class="fa fa-undo mgn-right8" aria-hidden="true"></i>
						Rotate left
					</button>
				</div>
				<div class="col-xs-3">
					<button type="button" class="white-bg" (click)="rotateImageService.onRotateClick('right')">
						Rotate right
						<i class="fas fa-redo-alt mgn-left8" aria-hidden="true"></i>
					</button>
				</div>
			</div>
		</ng-container>

		<div class="row mgn-top60">
			<div class="col-sm-8 col-sm-offset-2">
				<div class="flex justify-between">
					<button class="gray-bg-dark !w-5/12" type="button" (click)="fileUploadModalService.onCancelClick()">Cancel</button>
					<button
						[disabled]="!form.valid || !fileUploadStateService.uploadData.TargetLibraryId"
						type="submit"
						[ngClass]="!form.valid || !fileUploadStateService.uploadData.TargetLibraryId ? 'setOpacityLow' : ''"
						class="blue-bg-darkest white-text !w-5/12"
						(click)="fileUploadModalService.onUploadClick()"
					>
						Upload
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
