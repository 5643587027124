<div class="container-fluid">
	<nav class="row dark-bg ht68 ln-height68 relative">
		<div *ngIf="isEmployee()" class="col-sm-1 ht68 hidden-xs">
			<div class="left mgn-top12 in-block">
				<div *ngIf="internalMenuVisible" class="ht60 absolute z setWidth50 pointer"></div>
				<portal-hamburger [isChecked]="internalMenuVisible" (onClickNotify)="onInternalMenuClick()"></portal-hamburger>
			</div>
		</div>

		<div *ngIf="internalMenuVisible" (window:mouseup)="autoCloseMegaMenuDropdown($event)" class="col-sm-12 absolute top62 setZ">
			<hd-w24-employee-only-mega-menu (buttonClickNotify)="onMegaMenuButtonClick()"></hd-w24-employee-only-mega-menu>
		</div>

		<div *ngIf="headerMenuVisible()" class="col-xs-7 col-sm-3 col-md-2 ht68">
			<a [routerLink]="['/my-products']"><img class="wdth192" src="assets/header-logo.png" /></a>
		</div>
		<div *ngIf="!headerMenuVisible()" class="col-xs-7 col-sm-3 col-md-2 ht68">
			<img class="wdth192" src="assets/logo-red.png" />
		</div>

		<div *ngIf="isEmployee()" style="z-index: 100" class="hidden-xs col-sm-4 col-md-3 ht68 relative">
			<portal-client-switcher></portal-client-switcher>
		</div>

		<div
			*ngIf="headerMenuVisible()"
			class="{{
				isEmployee() ? 'col-lg-4' : 'col-lg-4 col-lg-offset-4'
			}} hidden-xs hidden-sm hidden-md white-text text-right header-links-wrapper relative left56"
		>
			<a
				class="relative link white-text pd-right16 pd-left16 pd-top6 pd-bottom6 {{ browserDetectorService.osIsWindows() ? 'semi-bold' : null }}"
				[routerLink]="['/my-products']"
			>
				<i class="fas fa-boxes ft-size18 mgn-right10" aria-hidden="true"></i>
				My Products
			</a>
			<a
				[ngClass]="{ active: toolsMenuVisible }"
				class="relative link white-text pd-right16 pd-left16 pd-top6 pd-bottom6 {{ browserDetectorService.osIsWindows() ? 'semi-bold' : null }}"
				(click)="onToolsClick()"
			>
				<i class="fas fa-tools ft-size18 mgn-right10" aria-hidden="true"></i>
				Tools
			</a>

			<hd-menu
				*ngIf="toolsMenuVisible"
				(window:mouseup)="autoCloseDropdown($event)"
				[menu]="toolsMenu"
				(menuItemClickNotify)="onToolsDropdownItemClick($event)"
				[setTop]="'top48'"
				[wrapperId]="'tools-menu'"
				(toolsMenuVisibleChange)="onToolsMenuVisibleChange($event)"
			></hd-menu>

			<a
				[ngClass]="{ active: helpMenuVisible }"
				class="relative link white-text pd-right16 pd-left16 pd-top6 pd-bottom6 {{ browserDetectorService.osIsWindows() ? 'semi-bold' : null }}"
				(click)="onHelpClick()"
			>
				<i class="fa fa-question ft-size18 mgn-right10" aria-hidden="true"></i>
				Help
			</a>
			<hd-menu
				*ngIf="helpMenuVisible"
				(window:mouseup)="autoCloseDropdown($event)"
				[menu]="helpMenu"
				[setTop]="'top48'"
				[wrapperId]="'help-menu'"
				(helpMenuVisibleChange)="onHelpMenuVisibleChange($event)"
			></hd-menu>
		</div>

		<div
			*ngIf="headerMenuVisible()"
			class="col-xs-2 col-md-4 {{ !isEmployee() ? 'col-xs-offset-3 col-sm-offset-7 col-md-offset-6' : 'col-xs-offset-3 col-sm-offset-2' }} mgn-top8 hidden-lg"
		>
			<div class="right in-block">
				<portal-hamburger [isChecked]="mobileMenuChecked" (onClickNotify)="toggleMobileMenu()"></portal-hamburger>
			</div>
		</div>

		<hd-menu
			*ngIf="mobileMenuChecked"
			class="hidden-lg"
			[menu]="mobileMenu"
			(mouseOverNotify)="onMobileMenuItemHover($event)"
			[setRight]="'right16'"
			[setTop]="'top68'"
		></hd-menu>

		<hd-menu
			*ngIf="toolsMobileMenuVisible"
			[menu]="toolsMenu"
			(menuItemClickNotify)="onToolsDropdownItemClick($event)"
			[setRight]="'right195'"
			[setTop]="'top167'"
		></hd-menu>

		<hd-menu *ngIf="helpMobileMenuVisible" [fontSize]="'ft-size15'" [menu]="helpMenu" [setRight]="'right195'" [setTop]="'top204'"></hd-menu>

		<div
			*ngIf="appStateService.currentUser.Role !== enumUserRole.VIDEO_CONTENT_CONTRACTOR"
			class="{{ !headerMenuVisible() ? 'col-lg-offset-4' : null }} col-lg-2  hidden-xs hidden-sm hidden-md text-right"
		>
			<a
				[ngClass]="{ active: logoutVisible }"
				class="relative link white-text pd-left16 pd-top6 pd-bottom6 {{ browserDetectorService.osIsWindows() ? 'semi-bold' : null }}"
				(click)="onUserNameClick()"
			>
				Welcome, {{ currentUserFriendlyName }}
			</a>
		</div>

		<div *ngIf="appStateService.currentUser.Role === enumUserRole.VIDEO_CONTENT_CONTRACTOR" class="col-lg-offset-8 col-lg-2 text-right">
			<a
				[ngClass]="{ active: logoutVisible }"
				class="relative link white-text pd-left16 pd-top6 pd-bottom6 {{ browserDetectorService.osIsWindows() ? 'semi-bold' : null }}"
				(click)="onUserNameClick()"
			>
				Welcome, {{ currentUserFriendlyName }}
			</a>
		</div>

		<div *ngIf="logoutVisible" id="logout-menu" class="absolute shadow top48 dropdown-menu dark-bg-light">
			<a (click)="onLogoutClick()" class="gray-text">
				<i class="fas fa-sign-out-alt mgn-right8 ft-size16" aria-hidden="true"></i>
				Sign out
			</a>
		</div>
	</nav>
	<div *ngIf="!appStateService.currentClient.IsDemoAccount && appStateService.currentUser.IsEmployee" class="row pd-top5 pd-bottom5 yellow-bg">
		<div class="col-xs-12 text-center">
			<p class="ft-size18">
				<i class="fas fa-bomb mgn-right15"></i>
				THIS IS A LIVE CUSTOMER ACCOUNT. PROCEED WITH CAUTION.
			</p>
		</div>
	</div>
</div>
