import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStateService } from 'src/app/core/services';
import { environment } from '../../../environments/environment';
import { DeliveryQueue } from '../api-models/delivery';
import { PlayersDragDrop } from '../view-models/admin';

@Injectable({
	providedIn: 'root'
})
export class DeliveryService {
	constructor(private appStateService: AppStateService, private httpClient: HttpClient) {}

	public getPlaylistPlayers(playlistId: number): Observable<PlayersDragDrop[]> {
		const { currentClient, currentUser } = this.appStateService;
		const getDeliveryQueuePlayerList$ = this.httpClient.get<DeliveryQueue[]>(
			`${environment.deliveryUrl}DeliveryQueue/PlayerList/${playlistId}/${currentClient.Id}`
		);
		const getPlayersByUser$ = this.httpClient.get<PlayersDragDrop[]>(`${environment.adminUrl}CoreUsers/${currentUser.UserId}/Players`);

		return forkJoin({ deliveryQueuePlayerList: getDeliveryQueuePlayerList$, playersByUser: getPlayersByUser$ }).pipe(
			map(({ deliveryQueuePlayerList, playersByUser }) => {
				return playersByUser
					.filter((player) => deliveryQueuePlayerList.some((deliveryPlayer) => deliveryPlayer.PlayerId === player.Id))
					.map((player) => {
						const deliveryQueue = deliveryQueuePlayerList.find((deliveryPlayer) => deliveryPlayer.PlayerId === player.Id);
						return { ...player, deliveryQueue };
					});
			})
		);
	}
}
