import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { fabric } from 'fabric';
import { Subscription } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import { AppStateService, UtilitiesService } from 'src/app/core/services';
import { ContentVM } from 'src/app/shared/components/content-container/content/_models/content-view';
import { ContentService, ContentViewService } from 'src/app/shared/components/content-container/content/_services';
import { ContentViewStateService } from 'src/app/shared/services';
import { ContentViewState } from 'src/app/shared/view-models';
import { Create24Area } from '../_models/create-24-state.enum';
import { Create24Service } from '../create-24.service';
import { ExportToLibraryService } from '../export-to-library/export-to-library.service';
import { EditorState } from './_models';
import { LayersService, StateService } from './_services';
import { CanvasObjService, CanvasService } from './canvas/_services';
import { CountdownAndDateService } from './create-layer-sidebar/countdown-and-date/countdown-and-date.service';
import { TextService } from './layer-detail/_services';
import { FeedService } from './layer-detail/feed/feed.service';
import { InitService, LayersTimelineWrapperService } from './timeline/layers-timeline-wrapper/_services';

@Component({
	selector: 'c24-main-editor',
	templateUrl: './main-editor.component.html',
	styleUrls: ['./main-editor.component.scss'],
	providers: [ExportToLibraryService]
})
export class MainEditorComponent implements OnInit, OnDestroy {
	private subs: Subscription[] = [];

	constructor(
		private appStateService: AppStateService,
		private canvasService: CanvasService,
		private canvasObjService: CanvasObjService,
		private contentViewService: ContentViewService,
		private countdownAndDateService: CountdownAndDateService,
		private create24Service: Create24Service,
		private contentService: ContentService,
		private cvStateService: ContentViewStateService,
		private feedService: FeedService,
		private initService: InitService,
		private layersService: LayersService,
		private ltwService: LayersTimelineWrapperService,
		private stateService: StateService,
		private textService: TextService,
		private utilService: UtilitiesService
	) {
		this.onExistingProjectSelect();
		this.onImgLoaded();
	}

	ngOnInit(): void {
		this.addCustomFonts();
		const { c24ActiveArea } = this.create24Service;
		//Load editor state on browser refresh if exists
		if (this.appStateService.getSessionItem('c24State')) {
			let state: EditorState = JSON.parse(this.appStateService.getSessionItem('c24State'));
			//this needs to be defined on page refresh
			this.canvasService.canvasContainerWidth = JSON.parse(this.appStateService.getSessionItem('canvasContainerWidth'));
			this.loadProject(state);
			this.canvasObjService.resetObjValues();
		} else {
			this.canvasService.canvas = new fabric.Canvas('video-canvas');
			//Only add transparent layer if creating a new project
			if (c24ActiveArea !== Create24Area.EDIT_PROJECT) {
				this.canvasService.addTransparentLayer();
			}
		}
		if (this.appStateService.getSessionItem('c24Feed') && this.appStateService.getSessionItem('c24Feed') !== 'undefined') {
			this.canvasService.feed = JSON.parse(this.appStateService.getSessionItem('c24Feed'));
		}
		if (this.appStateService.getSessionItem('c24FeedItem') && this.appStateService.getSessionItem('c24FeedItem') !== 'undefined') {
			this.canvasService.feedItem = JSON.parse(this.appStateService.getSessionItem('c24FeedItem'));
		}
	}

	private addCustomFonts(): void {
		const { CustomFontJson } = this.appStateService.currentClient;
		if (CustomFontJson) {
			JSON.parse(CustomFontJson).client_fonts.forEach((font) => {
				this.textService.fontList.push({ name: font.font_name });
			});
			this.textService.fontList = this.utilService.sortItems(this.textService.fontList, 'name');
		}
	}

	onExistingProjectSelect(): void {
		this.subs.push(
			this.contentViewService.onC24ExistingProjectSelect$
				.pipe(
					switchMap((content: ContentVM) =>
						this.contentService.getContentFiles$(content).pipe(
							map((contentWithContentFiles) => {
								const state: EditorState = JSON.parse(contentWithContentFiles.contentFiles[0].Create24ProjectJsonV4);
								return { state, content };
							})
						)
					)
				)
				.subscribe(({ state, content }) => {
					const existingProjectSelect: boolean = true;
					this.stateService.c24Content = content;
					this.loadProject(state, existingProjectSelect);
				})
		);
	}

	onImgLoaded() {
		this.subs.push(
			this.stateService.imgLoaded$.subscribe(() => {
				//If more than one layer is the transparent layer, remove it...fix for previous bug,
				//should only be one transparent layer
				this.canvasService.canvas.getObjects().forEach((obj) => {
					let moreThanOneTransLayer: boolean = this.canvasService.canvas.getObjects().filter((obj) => obj.layerId === 0).length > 1;
					if (moreThanOneTransLayer) {
						if (obj.layerId === 0) {
							this.canvasService.canvas.remove(obj);
						}
					}
				});

				//Img load is async, set correct z-index of all objects here
				//after img load is complete
				this.canvasService.canvas.getObjects().forEach((obj) => {
					obj.moveTo(obj.layerId); //Passing layerId as z-index
					if (obj.isFeedImageBoundingBox) {
						obj.bringToFront();
					}
				});

				this.canvasService.canvas.renderAll();
			})
		);
	}

	//Preserve state on browser refresh
	@HostListener('window:beforeunload', ['$event'])
	unloadNotification($event) {
		this.appStateService.setSessionItem('c24State', JSON.stringify(this.stateService.state()));
		this.appStateService.setSessionItem('c24Feed', JSON.stringify(this.canvasService.feed));
		this.appStateService.setSessionItem('c24FeedItem', JSON.stringify(this.canvasService.feedItem));
		this.appStateService.setSessionItem('canvasContainerWidth', JSON.stringify(this.canvasService.canvasContainerWidth));
	}

	private loadProject(loadedState: EditorState, existingProjectSelect?: boolean): void {
		const state = {
			...loadedState,
			canvasObjs: JSON.stringify({
				...JSON.parse(loadedState.canvasObjs),
				objects: this.canvasService.omitDuplicateLayerId(JSON.parse(loadedState.canvasObjs).objects, 'layerId')
			})
		};
		if (!existingProjectSelect) {
			this.ltwService.canvas = new fabric.Canvas('canvas');
			this.canvasService.canvas = new fabric.Canvas('video-canvas');
		}
		// The editor shows a background color if bgContent is not defined
		if (state.bgContent) {
			this.canvasService.bgContent = { ...state.bgContent, previewUrl: state.bgContent.previewUrl.replace(/Master/gi, 'Preview') };
		}
		this.ltwService.previousCanvasWidth = state.previousTimelineCanvasWidth;
		this.canvasService.canvas.preserveObjectStacking = true;
		this.canvasService.feed = state.feed;
		this.canvasService.feedItem = state.feedItem;
		this.layersService.layers = state.layers;
		this.stateService.c24ContentId = state.c24ContentId;
		this.layersService.activeLayer = this.layersService.layers.find((layer) => layer.id === state.activeLayer?.id);
		this.cvStateService.viewState = new ContentViewState();
		let duration: number = this.canvasService.bgContent?.contentFiles[0].DurationMs;
		duration = duration > 0 ? duration : 100000;
		this.ltwService.contentDuration = duration;
		this.stateService.addEachCanvasObj(state);
		this.stateService.addEachTimelineObj(state);
		this.ltwService.canvas.setActiveObject(this.ltwService.canvas.getObjects().find((obj) => obj.layerId === state.activeLayer?.id));
		this.initService.timelineInit();
		if (state?.countdownSettings) {
			this.countdownAndDateService.countdownDate = state.countdownSettings.countdownDate;
			this.countdownAndDateService.countdownInterval = state.countdownSettings.countdownInterval;
			this.countdownAndDateService.mode = state.countdownSettings.mode;
			this.countdownAndDateService.view = state.countdownSettings.view;
			this.countdownAndDateService.displayOption = state.countdownSettings.displayOption;
		}

		//If content exists, make sure bgColor is null
		if (state.bgContent) {
			this.layersService.layers.find((layer) => layer.id === 0).canvasObj.blankBgColor = null;
		}

		if (this.layersService.atLeastOneFeedLayer() && !this.canvasService.feedsList) {
			this.feedService.getFeeds();
		}

		setTimeout(() => {
			this.initService.setGroupControls();
		}, 1000);

		//Reset durations back to total
		this.layersService.layers.forEach((layer) => {
			layer.transInDuration = layer.totalTransDuration;
			layer.transOutDuration = layer.totalTransDuration;
		});
	}

	ngOnDestroy() {
		this.appStateService.removeSessionItem('c24State');
		this.appStateService.removeSessionItem('c24Feed');
		this.appStateService.removeSessionItem('c24FeedItem');
		this.appStateService.removeSessionItem('canvasContainerWidth');

		this.layersService.layers = [];
		this.stateService.c24ContentId = null;
		this.canvasService.feed = null;
		this.canvasService.feedItem = null;

		//Reset font list to remove client custom fonts
		const { CustomFontJson } = this.appStateService.currentClient;
		if (CustomFontJson) {
			this.textService.fontList = this.textService.fontList.filter((font) => {
				return !JSON.parse(CustomFontJson).client_fonts.some((f) => f.font_name === font.name);
			});
		}
		this.subs.forEach((sub) => sub.unsubscribe());
	}
}
