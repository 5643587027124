import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Players } from 'src/app/shared/api-models/admin';
import { environment } from '../../../environments/environment';
import { AdminDataService, AppStateService } from '../../core/services';
import { DeliveryQueue } from '../../shared/api-models/delivery';
import { PlayersDragDrop } from '../../shared/view-models/admin';
import { ChoosePlayersDataService } from './choose-players-data.service';
import { PlaylistService } from './playlist.service';

@Injectable()
export class ScheduledPlayersService {
	public scheduledPlayers: PlayersDragDrop[] = [];

	constructor(
		private adminDataService: AdminDataService,
		private appStateService: AppStateService,
		private choosePlayersDataService: ChoosePlayersDataService,
		private httpClient: HttpClient,
		private playlistService: PlaylistService
	) {}

	public getScheduledPlayers(): Observable<PlayersDragDrop[]> {
		const { currentClient, currentUser } = this.appStateService;
		const { playlist } = this.playlistService;

		const getScheduledPlayers$: Observable<DeliveryQueue[]> = this.httpClient.get<DeliveryQueue[]>(
			`${environment.deliveryUrl}DeliveryQueue/PlayerList/${playlist.Id}/${currentClient.Id}`
		);
		const getPlayersByUser$: Observable<Players[]> = this.httpClient.get<Players[]>(`${environment.adminUrl}CoreUsers/${currentUser.UserId}/Players`);

		return forkJoin([getScheduledPlayers$, getPlayersByUser$]).pipe(
			switchMap((res) => {
				const [scheduledPlayers, playersByUser] = res;

				const scheduledPlayers$ = scheduledPlayers
					.filter((scheduledPlayer) => {
						return playersByUser.some((playerByUser) => playerByUser.Id === scheduledPlayer.PlayerId);
					})
					.map((queue) => {
						return this.httpClient.get(environment.adminUrl + `CorePlayers/${queue.PlayerId}`).pipe(
							map((playerArr: PlayersDragDrop[]) => {
								return playerArr[0];
							})
						);
					});
				return forkJoin(scheduledPlayers$).pipe(
					map((players: PlayersDragDrop[]) => {
						return players
							.map((player, index) => {
								return this.adminDataService.setPlayerViewModel(player, scheduledPlayers[index]);
							})
							.filter((player) => !this.choosePlayersDataService.playerDisabled(player.PlayerModel));
					})
				);
			})
		);
	}
}
