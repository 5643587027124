import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { PlayersDragDrop } from 'src/app/shared/view-models/admin';
import { DateTimeService } from '../../../../core/services';
import { Playlists } from '../../../api-models/content';
import { DeliveryService } from '../../../services';

@Component({
	selector: 'pd-scheduled-players',
	templateUrl: './scheduled-players.component.html',
	styleUrls: ['./scheduled-players.component.scss']
})
export class ScheduledPlayersComponent implements OnInit {
	@Input() playlist: Playlists;
	@Output() exitClickNotify = new EventEmitter<any>();

	public players$: Observable<PlayersDragDrop[]>;

	constructor(public dateTimeService: DateTimeService, public deliveryService: DeliveryService) {}

	ngOnInit() {
		this.players$ = this.deliveryService.getPlaylistPlayers(this.playlist.Id);
		this.players$.subscribe(console.log);
	}
}
