import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		path: 'my-players/:productRoute',
		loadChildren: () => import('./my-players/my-players.module').then((mod) => mod.MyPlayersModule)
	},
	{
		path: 'playlist-builder',
		loadChildren: () => import('./playlist-builder/playlist-builder.module').then((mod) => mod.PlaylistBuilderModule)
	},
	{
		path: 'user-manager',
		loadChildren: () => import('./user-manager/user-manager.module').then((mod) => mod.UserManagerModule)
	},
	{
		path: 'saved-playlists/:productRoute',
		loadChildren: () => import('./saved-playlists/saved-playlists.module').then((mod) => mod.SavedPlaylistsModule)
	},
	{
		path: 'my-tickers',
		loadChildren: () => import('./my-tickers/my-tickers.module').then((mod) => mod.MyTickersModule)
	},
	{
		path: 'custom-data-feeds',
		loadChildren: () => import('./my-feeds/my-feeds.module').then((mod) => mod.MyFeedsModule)
	},
	{
		path: 'crm/client-details',
		loadChildren: () => import('./crm/crm.module').then((mod) => mod.CrmModule)
	},
	{
		path: 'record-24',
		loadChildren: () => import('./user-tools/record-24/record-24.module').then((mod) => mod.Record24Module)
	},
	{
		path: 'ticket-system',
		loadChildren: () => import('./system-tools/ticket-system/ticket-system.module').then((mod) => mod.TicketSystemModule)
	},
	{
		path: 'create-24',
		loadChildren: () => import('./user-tools/create-24/create-24.module').then((mod) => mod.Create24Module)
	},
	{
		path: 'logo-overlay',
		loadChildren: () => import('./logo-overlay/logo-overlay.module').then((mod) => mod.LogoOverlayModule)
	},
	{
		path: 'group-manager/:productRoute',
		loadChildren: () => import('./group-manager/group-manager.module').then((mod) => mod.GroupManagerModule)
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./contact-us/contact-us.module').then((mod) => mod.ContactUsModule)
	},
	{
		path: 'global-settings',
		loadChildren: () => import('./global-settings/global-settings.module').then((mod) => mod.GlobalSettingsModule)
	},
	{
		path: 'large-file-transfer',
		loadChildren: () => import('./large-file-transfer/large-file-transfer.module').then((mod) => mod.LargeFileTransferModule)
	},
	{
		path: 'player-playlist-history/:productRoute/:playerId',
		loadChildren: () => import('./player-playlist-history/player-playlist-history.module').then((mod) => mod.PlayerPlaylistHistoryModule)
	},
	{
		path: 'history-log/:productRoute/:playerId',
		loadChildren: () => import('./player-playlist-history/player-playlist-history.module').then((mod) => mod.PlayerPlaylistHistoryModule)
	},
	{
		path: 'screen-layouts/:productRoute',
		loadChildren: () => import('./screen-layouts/screen-layouts.module').then((mod) => mod.ScreenLayoutsModule)
	},
	{
		path: 'featured-content',
		loadChildren: () => import('./featured-content/featured-content.module').then((mod) => mod.FeaturedContentModule)
	},
	{
		path: 'newsletter',
		loadChildren: () => import('./client-newsletter/client-newsletter.module').then((mod) => mod.ClientNewsletterModule)
	},
	{
		path: ':activeFeatureArea/:productRoute/browser',
		loadChildren: () => import('./shared/components/content-browser/content-browser.module').then((mod) => mod.ContentBrowserModule)
	},
	{
		path: 'ai',
		loadChildren: () => import('./ai-message-creator/ai-message-creator.module').then((mod) => mod.AIMessageCreatorModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
