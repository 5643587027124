import { Component } from '@angular/core';
import { AppStateService } from 'src/app/core/services';

@Component({
	selector: 'portal-header-and-title',
	templateUrl: './header-and-title.component.html',
	styleUrls: ['./header-and-title.component.scss']
})
export class HeaderAndTitleComponent {
	get productColor() {
		return this.appStateService.product?.color || 'red';
	}

	constructor(private appStateService: AppStateService) {}
}
