import { Injectable } from '@angular/core';

import { ClientIds } from 'src/app/shared/api-models/admin/client-ids.enum';
import { UserRole } from 'src/app/shared/api-models/admin/user-role.enum';
import { ContentVM } from 'src/app/shared/components/content-container/content/_models/content-view';
import { FeatureArea } from 'src/app/shared/view-models';
import { ContentLibraries } from '../../shared/api-models/content';
import { ContentFilesVM } from '../../shared/components/content-container/content/_models';
import { AppStateService } from './app-state.service';

@Injectable({
	providedIn: 'root'
})
export class SecurityService {
	constructor(private appStateService: AppStateService) {}

	public filterLibraries(libraries: ContentLibraries[], featureArea: FeatureArea = FeatureArea.CONTENT_MANAGER): ContentLibraries[] {
		const currentClient = this.appStateService.currentClient;

		return libraries
			.filter((userLibrary) => {
				//Filter out global libraries when an employee is logged in as a different client
				if (currentClient.Id !== ClientIds.WORKS24_OFFICE_ACCOUNT) {
					return !userLibrary.IsGlobal;
				}

				switch (true) {
					//Filter out globals if user role does not have write access
					case !this.userRoleHasWriteAccess():
						if (!userLibrary.IsGlobal) {
							return true;
						}
						return;

					//return all libraries if user has write access
					case this.userRoleHasWriteAccess():
						return true;
				}
			})
			.filter((lib) => {
				const hideFromPortal: boolean = currentClient.Id !== ClientIds.WORKS24_OFFICE_ACCOUNT && currentClient.Name !== 'Creative Office'; //Works24 Office, Creative Office

				if (featureArea === FeatureArea.CONTENT_MANAGER && hideFromPortal) {
					return lib.WriteAccess && !lib.HideFromPortal;
				}
				return lib.WriteAccess;
			});
	}

	private userRoleHasWriteAccess(): boolean {
		const { Role } = this.appStateService.currentUser;
		return Role === UserRole.ADMINISTRATOR || Role === UserRole.CSR || Role === UserRole.SCRIPT_WRITER || this.userRole(Role) === UserRole.CONTENT_CREATOR;
	}

	//This method for editing content title, script, deleting content,
	//dragging content to new category, updating gender label
	public writeAccessEnabled(content: ContentVM, contentFile?: ContentFilesVM): boolean {
		if (this.appStateService.currentUser.IsEmployee && this.userIsContentManagerCsrOrAdmin()) {
			return true;
		}

		if (!this.appStateService.currentUser.IsEmployee && !content.isGlobal && this.userIsContentManagerOrSupervisor()) {
			if (contentFile && !contentFile.createdByUser.IsEmployee) {
				return true;
			}
			if (contentFile && contentFile.createdByUser.IsEmployee) {
				return false;
			}
			if (!contentFile) {
				return this.userIsAdmin() || this.userIsContentManagerOrSupervisor();
			}
		}
		return false;
	}

	public contentManagerCsrOrAdmin(): boolean {
		const { Role } = this.appStateService.currentUser;
		return (
			Role === UserRole.CONTENT_MANAGER ||
			Role === UserRole.CSR ||
			Role === UserRole.ADMINISTRATOR ||
			Role === UserRole.AUDIO_CONTENT_CREATOR ||
			Role === UserRole.VIDEO_CONTENT_CREATOR
		);
	}

	public userIsContentManagerCsrOrAdmin(): boolean {
		const { Role } = this.appStateService.currentUser;
		return (
			Role === UserRole.CONTENT_MANAGER ||
			Role === UserRole.CSR ||
			Role === UserRole.ADMINISTRATOR ||
			Role === UserRole.AUDIO_CONTENT_CREATOR ||
			Role === UserRole.VIDEO_CONTENT_CREATOR
		);
	}

	public userSeesAllLibraries(): boolean {
		const { Role } = this.appStateService.currentUser;
		const currentClient = this.appStateService.currentClient;
		return (
			(currentClient.Id === ClientIds.WORKS24_OFFICE_ACCOUNT || currentClient.Name === 'Creative Office') &&
			this.userIsContentManagerCsrOrAdmin() &&
			Role !== UserRole.CONTENT_MANAGER
		);
	}

	public userRole(userRole: string): string {
		switch (userRole) {
			case UserRole.VIDEO_CONTENT_CONTRACTOR:
			case UserRole.VOICE_TALENT:
			case UserRole.AUDIO_CONTENT_CREATOR:
			case UserRole.VIDEO_CONTENT_CREATOR:
				return UserRole.CONTENT_CREATOR;
			default:
				return userRole;
		}
	}

	public userRoleSalesperson(): boolean {
		return this.appStateService.currentUser.Role === UserRole.SALESPERSON;
	}

	public userRoleAdminOrCsr(): boolean {
		const userRole = this.appStateService.currentUser.Role;
		return userRole === UserRole.ADMINISTRATOR || userRole === UserRole.CSR;
	}

	public userIsAdmin(): boolean {
		const currentUser = this.appStateService.currentUser;
		return currentUser.Role === UserRole.ADMINISTRATOR;
	}

	public userRoleAdminOrVideo(userRole: string): boolean {
		return userRole === UserRole.ADMINISTRATOR || userRole === UserRole.VIDEO_CONTENT_CREATOR;
	}

	public userRoleAdminCsrOrSalesman(userRole: string): boolean {
		return userRole === UserRole.ADMINISTRATOR || userRole === UserRole.CSR || userRole === UserRole.SALESPERSON;
	}

	public userIsContractor(): boolean {
		const { Role } = this.appStateService.currentUser;
		return Role === UserRole.VIDEO_CONTENT_CONTRACTOR || Role === UserRole.VOICE_TALENT;
	}

	public userIsVideoContractor(): boolean {
		return this.appStateService.currentUser.Role === UserRole.VIDEO_CONTENT_CONTRACTOR;
	}

	public userIsAudioContentCreatorOrVoiceTalent(): boolean {
		const { Role } = this.appStateService.currentUser;
		return Role === UserRole.AUDIO_CONTENT_CREATOR || Role === UserRole.VOICE_TALENT;
	}

	public userIsNatalieOrKelsey(): boolean {
		const currentUser = this.appStateService.currentUser;
		//Kelsey Hunt/Natalie Van Oosten/Austin Harms (dev)
		return (
			(currentUser.UserId === 19 || currentUser.UserId === 35 || currentUser.UserId === 106) &&
			this.appStateService.currentClient.Id === ClientIds.WORKS24_OFFICE_ACCOUNT
		);
	}

	public userIsAdminOrNatalie(): boolean {
		return this.appStateService.currentUser.Role === UserRole.ADMINISTRATOR || this.appStateService.currentUser.UserId === 35;
	}

	private userIsContentManagerOrSupervisor(): boolean {
		const { Role } = this.appStateService.currentUser;
		return Role === UserRole.CONTENT_MANAGER || Role === UserRole.SUPERVISOR;
	}

	public userIsEmployee(): boolean {
		return this.appStateService.currentUser.IsEmployee;
	}

	public userHasAiMessageCreatorPermission() {
		const { Role } = this.appStateService.currentUser;
		const { currentClient } = this.appStateService;
		if (!currentClient.AllowAI) {
			return false;
		}
		switch (Role) {
			case UserRole.ADMINISTRATOR:
			case UserRole.SUPERVISOR:
			case UserRole.CONTENT_MANAGER:
			case UserRole.CSR:
			case UserRole.SALESPERSON:
			case UserRole.TECHNICIAN:
				return true;
			default:
				return false;
		}
	}
}
