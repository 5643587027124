<footer [ngClass]="{ 'gray-bg': !isDevMode, 'pink-bg': isDevMode }" class="ht-30 ln-height30 setWidth100">
	<div [ngClass]="{ 'white-text': isDevMode }" class="center hidden-xs hidden-sm">
		&#169;{{ fullYear }}
		<a href="http://works24.com" target="_blank">Works24</a>
		| All Rights Reserved. | 3508 French Park Drive, Suite One | Edmond, OK 73034 | 1-800-460-4653 | Fax: 1-800-460-9876 |
		<a href="http://www.works24.com/contact.html" target="_blank">Contact Us</a>
		<div class="ft-size12 hidden-xs hidden-sm hidden-md in-block mgn-left10">
			<em>
				Build date: October 25, 2024
				<span *ngIf="isDevMode">- Test branch</span>
			</em>
		</div>
	</div>
	<div class="center hidden-md hidden-lg">
		&#169;{{ fullYear }}
		<a href="http://works24.com" target="_blank">Works24</a>
		| All Rights Reserved. |
		<a href="http://www.works24.com/contact.html" target="_blank">Contact Us</a>
	</div>
</footer>
