import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import * as uuid from 'uuid';

import { AppErrorService } from 'src/app/core/app-error/app-error.service';
import { ProductRoute } from 'src/app/shared/api-models/admin/product-route.enum';
import { environment } from '../../../environments/environment';
import { AppStateService, DateTimeService, Events, MessageService, PlaylistJsonService } from '../../core/services';
import { DeliveryJson, DeliveryQueue } from '../../shared/api-models/delivery';
import { DragDropLeftRightService } from '../../shared/components/drag-drop-left-right/drag-drop-left-right.service';
import { PlayersDragDrop } from '../../shared/view-models/admin';
import { PbPlaylist } from '../../shared/view-models/content';
import { ChoosePlayersDataService } from './choose-players-data.service';
import { PlaylistService } from './playlist.service';
import { WizardService } from './wizard.service';

@Injectable()
export class ScheduleService {
	public btnString: string;
	public savingInProgress: boolean;

	constructor(
		private appErrorService: AppErrorService,
		private appStateService: AppStateService,
		private choosePlayersDataService: ChoosePlayersDataService,
		private dateTimeService: DateTimeService,
		private dragDropLeftRightService: DragDropLeftRightService,
		private httpClient: HttpClient,
		private messageService: MessageService,
		private playlistJsonService: PlaylistJsonService,
		private playlistService: PlaylistService,
		private wizardService: WizardService
	) {}

	public scheduleLaterDisabled(): boolean {
		if (!this.playlistService.playlist.startDate) {
			return true;
		}
		return this.dragDropLeftRightService.assignedItems.length < 1 || this.savingInProgress;
	}

	public onStartClick(str: 'startOnDateTime' | 'startNow'): void {
		if (!this.scheduleLaterDisabled() && str === 'startNow') {
			const confirmOk: boolean = confirm('You have selected a start date for this playlist, schedule now anyway?');
			if (!confirmOk) {
				return;
			}
		}
		this.btnString = str;
		if (str === 'startNow') {
			this.playlistService.playlist.startDate = this.dateTimeService.todayWithCurrentTimeUTC();
		}
		if (str === 'startOnDateTime') {
			if (this.appStateService.product.ProductName === 'On Hold') {
				this.playlistService.playlist.startDate = this.dateTimeService.localToUtc(this.playlistService.playlist.startDate.slice(0, -1));
			} else {
				this.playlistService.playlist.startDate = this.dateTimeService.midnightUTC(this.playlistService.playlist.startDate.slice(0, -1));
			}
		}
		this.messageService.publish(Events.savingPreloader, 1);
		this.savingInProgress = true;

		forkJoin(this.deliveryQueuePostArr$()).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
			setTimeout(() => {
				this.wizardService.advance(1);
				this.savingInProgress = false;
			}, 1400);
		});
	}

	public onVoipEmailToSelect(itemArr: string[]): void {
		itemArr = JSON.parse(JSON.stringify(itemArr));
		const mappedArr: any[] = itemArr.map((item: any) => {
			for (const prop in item) {
				if (prop === 'friendlyView') {
					delete item[prop];
				}
				if (prop === 'isSelected') {
					delete item[prop];
				}
			}
			return item;
		});
		this.playlistService.playlist.voipRecipients = JSON.stringify(mappedArr);
	}

	private deliveryQueuePostArr$(): Observable<DeliveryQueue>[] {
		return this.dragDropLeftRightService.assignedItems.map((player: PlayersDragDrop) => {
			const errorFn = (error: string, emailId: 'clientIdMismatch' | 'productIdMismatch') => {
				if (emailId === 'clientIdMismatch') {
					this.sendClientIdMismatchEmail(player).subscribe();
				} else if (emailId === 'productIdMismatch') {
					this.sendProductIdMismatchEmail(player).subscribe();
				}
				this.appErrorService.showAppError = true;
				const errorPayload = [error, `Unable to schedule your playlist, please contact your CSR.`];
				this.messageService.publish(Events.appError, errorPayload);
				console.error(error);
			};
			if (player.ClientId !== this.appStateService.currentClient.Id) {
				errorFn(
					`Current Client ID does not match player client ID... Current Client ID: ${this.appStateService.currentClient.Id}, Player Client ID: ${player.ClientId}`,
					'clientIdMismatch'
				);
			} else if (player.ProductTypeId !== this.appStateService.product.Id) {
				errorFn(
					`Current Product ID does not match player product ID... Current Product ID: ${this.appStateService.product.Id}, Player Product ID: ${player.ProductTypeId}`,
					'productIdMismatch'
				);
			} else {
				return this.deliveryQueuePost$(player);
			}
		});
	}

	private sendProductIdMismatchEmail(player: PlayersDragDrop): Observable<Object> {
		const obj = {
			To: 'devteam@works24.com',
			From: 'scheduleerror@works24.com',
			Subject: 'Product ID Does Not Match Player.ProductTypeId',
			Body:
				`Selected Product ID does not match player product type id... Current Product ID: ${this.appStateService.product.Id}, Player Product Type ID: ${player.ProductTypeId} \n \n` +
				`Current User ID: ${this.appStateService.currentUser.UserId} \n` +
				`Player ID: ${player.Id}`
		};
		//send email
		return this.httpClient.post(environment.adminUrl + 'Email', obj);
	}

	private sendClientIdMismatchEmail(player: PlayersDragDrop): Observable<Object> {
		const obj = {
			To: 'devteam@works24.com',
			From: 'scheduleerror@works24.com',
			Subject: 'Client ID Does Not Match Player.ClientId',
			Body:
				`Current Client ID does not match player client ID... Current Client ID: ${this.appStateService.currentClient.Id}, Player Client ID: ${player.ClientId} \n \n` +
				`Current User ID: ${this.appStateService.currentUser.UserId} \n` +
				`Player ID: ${player.Id}`
		};
		//send email
		return this.httpClient.post(environment.adminUrl + 'Email', obj);
	}

	private deliveryQueuePost$(player: PlayersDragDrop): Observable<DeliveryQueue> {
		return this.httpClient.post<DeliveryQueue>(`${environment.deliveryUrl}DeliveryQueue`, this.deliveryQueue(player, this.playlistService.playlist));
	}

	private startDate(startDate: string, player: PlayersDragDrop): string {
		if (this.btnString === 'startNow') {
			if (player.PlayerModelId === 1000000) {
				//Apple TV
				return this.dateTimeService.todayAtMidnightUTC();
			}
			return this.dateTimeService.todayWithCurrentTimeUTC();
		}
		return startDate;
	}

	public deliveryQueue(player: PlayersDragDrop, playlist: PbPlaylist): DeliveryQueue {
		const deliveryQueue: DeliveryQueue = new DeliveryQueue();

		deliveryQueue.DeliveryQueueMessageTypeId = 1;
		deliveryQueue.ProductId = player.ProductTypeId;
		deliveryQueue.ClientId = this.appStateService.currentClient.Id;
		deliveryQueue.UserId = this.appStateService.currentUser.UserId;
		deliveryQueue.PlayerId = player.Id;
		deliveryQueue.PlayerModelId = player.PlayerModelId;
		deliveryQueue.PlayerModelTypeId = player.PlayerModel.PlayerModelTypeId;
		deliveryQueue.IsExclusivePlaylist = true;
		deliveryQueue.PlaylistId = playlist.Id;
		deliveryQueue.PlaylistName = playlist.Name;
		deliveryQueue.PlaylistLastUpdate = new Date().toISOString();
		deliveryQueue.UnitIdentifier = player.UnitIdentifier;
		deliveryQueue.BatchIdentifier = uuid.v4();
		deliveryQueue.PlaylistJson = JSON.stringify(this.playlistJsonService.playlistJson(playlist, this.appStateService.product.Id));
		deliveryQueue.DeliveryJson = this.deliveryJson(player);
		deliveryQueue.SavedOn = new Date().toISOString();
		deliveryQueue.DeliveryStatusId = 2;
		deliveryQueue.DeliverOn = this.startDate(playlist.startDate, player);
		deliveryQueue.StartDate = this.startDate(playlist.startDate, player);
		deliveryQueue.StopDate = playlist.stopDate;
		deliveryQueue.DeliverTo = playlist.voipRecipients;

		const guid: string = uuid.v4();
		deliveryQueue.SecretId = guid;
		deliveryQueue.MemoryCapacity = player.PlayerModel.MemoryCapacity;

		if (this.playlistService.playlist.advancedScheduleActive) {
			deliveryQueue.PlaylistAdvancedSchedule = playlist.advancedSchedule;
		}
		return deliveryQueue;
	}

	private deliveryJson(player: PlayersDragDrop): string {
		const deliveryJson: DeliveryJson = new DeliveryJson();
		deliveryJson.PlayerId = player.Id;
		deliveryJson.ModelTypeName = player.PlayerModel.PlayerModelType.Name;
		deliveryJson.ModelTypeId = player.PlayerModel.PlayerModelTypeId;
		deliveryJson.ClientName = this.appStateService.currentClient.Name;
		deliveryJson.LocationName = player.Location.Name;
		deliveryJson.VoipNotify = player.VoipNotify;
		deliveryJson.PlayerName = player.PlayerName;
		deliveryJson.IsLongDistance = player.IsLongDistance;
		deliveryJson.RemoteAreaCode = player.RemoteAreaCode;
		deliveryJson.RemotePhone = player.RemotePhone;
		deliveryJson.RemotePassword = player.RemotePassword;
		deliveryJson.HoldVolume = player.HoldVolume;
		deliveryJson.VoipFormatId = this.voipFormatId(player);
		deliveryJson.VoipFormat = player.VoipFormat;
		deliveryJson.VoipFileModeId = this.voipFileModeId(player);
		return JSON.stringify(deliveryJson);
	}

	private voipFormatId(player: PlayersDragDrop): number | null {
		if (player.VoipFormatId) {
			return player.VoipFormatId;
		}
		//fall back to client record if no player.VoipFormatId exists
		if (this.appStateService.currentClient.DefaultVoipFormat) {
			return this.appStateService.currentClient.DefaultVoipFormat;
		}
	}

	private voipFileModeId(player: PlayersDragDrop): number | null {
		if (player.VoipFileModeId) {
			return player.VoipFileModeId;
		}
		//fall back to client record if no player.voipFileModeId exists
		if (this.appStateService.currentClient.DefaultVoipFileMode) {
			return this.appStateService.currentClient.DefaultVoipFileMode;
		}
	}

	public showVoipEmailTo(): boolean {
		return (
			this.appStateService.currentUser.IsEmployee &&
			this.choosePlayersDataService.clientHasAtLeastOneVoipPlayer &&
			this.appStateService.product.Route === ProductRoute.HOLD
		);
	}
}
