import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { DateTimeService, Events, MessageService, TypeGuardService } from 'src/app/core/services';
import { PlaylistService } from 'src/app/playlist-builder/_services';
import { PlaylistExamplesContentFileBridge, PlaylistsExamples } from 'src/app/shared/api-models/content';
import { ContentViewStateService, GetPlaylistDataService } from 'src/app/shared/services';
import { ContentItem, PlaylistsExamplesCompletePlaylist } from 'src/app/shared/view-models/content';
import { environment } from '../../../../../environments/environment';
import { ContentVM } from '../../content-container/content/_models/content-view';
import { LibrariesService } from './libraries.service';

@Injectable({
	providedIn: 'root'
})
export class FeaturedPlaylistsService {
	public showFeaturedPlaylists: boolean;
	public featuredPlaylists: PlaylistsExamples[];

	constructor(
		private cvStateService: ContentViewStateService,
		private dateTimeService: DateTimeService,
		private getPlaylistDataService: GetPlaylistDataService,
		private httpClient: HttpClient,
		private librariesService: LibrariesService,
		private messageService: MessageService,
		private playlistService: PlaylistService,
		private typeGuardService: TypeGuardService
	) {}

	public getFeaturedPlaylists(): void {
		let libraryIds: string = this.librariesService.libraries
			.map((library) => {
				return library.Id;
			})
			.toString();
		this.httpClient.get(`${environment.contentUrl}PlaylistExamples/List/${libraryIds}`).subscribe((featuredPlaylists: PlaylistsExamples[]) => {
			this.featuredPlaylists = featuredPlaylists.filter((p) => !p.IsDeleted);
		});
	}

	public onPlaylistClick(playlist: PlaylistsExamples): void {
		this.cvStateService.viewState.treeSelection = 'featured-playlist';
		this.getPlaylistExamplesComplete(playlist);
	}

	private getPlaylistExamplesComplete(playlist: PlaylistsExamples): void {
		this.httpClient
			.get(`${environment.contentUrl}PlaylistExamples/${playlist.Id}/Complete`)
			.subscribe((completePlaylist: PlaylistsExamplesCompletePlaylist) => {
				this.cvStateService.viewState.contentListVisible = true;
				this.librariesService.selectedFeaturedPlaylist = completePlaylist;

				const contentList = this.mapContent(completePlaylist);
				this.cvStateService.totalItemsContentList = contentList.length;
				this.cvStateService.contentList = contentList;
			});
	}

	public playlistExamplesContentFileBridge(playlistId: number, contentItem: ContentItem): PlaylistExamplesContentFileBridge {
		let bridge: PlaylistExamplesContentFileBridge = new PlaylistExamplesContentFileBridge();
		bridge.PlaylistExampleId = playlistId;
		bridge.ContentFileId = contentItem.contentFileId;
		bridge.IsMusicTrack = contentItem.isMusicTrack;
		bridge.Order = contentItem.order;
		bridge.StartDate = contentItem.startDate;
		bridge.StopDate = contentItem.stopDate;
		bridge.StillDuration = contentItem.stillDuration;
		if (!!contentItem.silenceMusic) {
			bridge.SilenceMusic = contentItem.silenceMusic;
		}
		if (!!contentItem.feedId) {
			bridge.FeedId = contentItem.feedId;
		}
		return bridge;
	}

	public softDelete(selectedPlaylist: PlaylistsExamples): void {
		this.messageService.publish(Events.savingPreloader, 1);
		let playlist = new PlaylistsExamples();
		playlist.IsDeleted = true;
		this.httpClient.patch<PlaylistsExamples>(`${environment.contentUrl}PlaylistExamples/${selectedPlaylist.Id}`, playlist).subscribe(() => {
			this.featuredPlaylists = this.featuredPlaylists.filter((p) => p.Id !== selectedPlaylist.Id);
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public updatePlaylistName(playlist: PlaylistsExamples): void {
		this.messageService.publish(Events.savingPreloader, 1);
		this.httpClient.patch(`${environment.contentUrl}PlaylistExamples/${playlist.Id}`, { Name: playlist.Name }).subscribe(() => {
			this.messageService.publish(Events.savingPreloader, 0);
		});
	}

	public mapContent(completePlaylist: PlaylistsExamplesCompletePlaylist): ContentVM[] {
		const status = this.playlistService.playlist?.status ?? null;
		const contentArr: ContentVM[] = completePlaylist.PlaylistFiles.map((file) => {
			return file.Content as ContentVM;
		});
		return contentArr
			.map((content) => {
				content.contentFiles = [completePlaylist.PlaylistFiles.find((file) => file.ContentId === content?.Id)] as any;
				let fileBridge = completePlaylist.PlaylistExamplesFileBridge.find((bridge) => bridge.ContentFileId === content.contentFiles[0].Id);
				content.contentFiles[0].previewUrl = this.getPlaylistDataService.previewUrl(content.contentFiles[0], content.ContentTypeId === 1);
				content.startDate = status === 'New' ? this.dateTimeService.todayAtMidnightUTC() : fileBridge.StartDate;
				content.stopDate = fileBridge.StopDate;

				if (this.typeGuardService.contentFileIsHold(content.contentFiles[0])) {
					content.contentFiles[0].isMusicTrack = fileBridge.IsMusicTrack;
				}

				delete content.contentFiles[0].Content;

				return content;
			})
			.filter((content) => {
				//Only return content where stop date is after today
				return moment(content.stopDate).isSameOrAfter(moment()) || content.stopDate === undefined || content.stopDate === null;
			});
	}
}
