import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ContentFilesVM } from '../../shared/components/content-container/content/_models';
import { ContentItem, PbPlaylist, SavedPlaylists } from '../../shared/view-models/content';

type PlaylistPreview = { isActive: boolean; selectedPlaylist: PbPlaylist | SavedPlaylists; audioObj?: any };

@Injectable({
	providedIn: 'root'
})
export class AudioPreviewService {
	public previouslySelectedContent: ContentItem;
	public previouslySelectedPlaylist: PbPlaylist | SavedPlaylists;

	public playlistPreview: PlaylistPreview[] = [];

	constructor(private httpClient: HttpClient) {}

	//for on hold product only
	public contentItemAudioPreview(selectedContentItem: ContentItem, contentFiles: ContentFilesVM[]): void {
		this.stopCurrentPreview(selectedContentItem, contentFiles);

		const contentFile = contentFiles.find((contentFile) => contentFile.Id === selectedContentItem.contentFileId);

		//pause preview if already active
		if (contentFile.audioPreview && contentFile.previewActive) {
			this.setPropsBackToDefault(contentFile);
			contentFile.audioPreview.pause();
			//initialize and play preview if not already active
		} else {
			this.setPreviewBtnProps('yellow-bg', 'Stop', 'dark-text', contentFile);

			contentFile.audioPreview = new Audio();
			contentFile.audioPreview.src = contentFile.previewUrl;
			contentFile.audioPreview.play();
		}
	}

	public stopCurrentPreview(selectedContentItem: ContentItem, contentFiles: ContentFilesVM[]): void {
		//Stop currently playing preview when clicking on another preview
		if (contentFiles) {
			contentFiles
				.filter((contentFile) => contentFile.Id !== selectedContentItem.contentFileId)
				.forEach((filteredContentFile) => {
					filteredContentFile.audioPreview ? filteredContentFile.audioPreview.pause() : null;
					filteredContentFile.previewActive = false;
					this.setPropsBackToDefault(filteredContentFile);
				});
		}
	}

	public getPlaylistPreview(playlist: PbPlaylist | SavedPlaylists): void {
		this.trackActivePreviews(playlist);

		//if a preview is active (only one will be at a time), get the preview
		if (playlist && this.playlistPreview.some((preview) => preview.isActive)) {
			this.activePreview().selectedPlaylist.previewBtnIcon = 'fas fa-pause';

			this.httpClient
				.get(`${environment.contentUrl}Playlists/${playlist.Id}/Preview`, {
					responseType: 'text'
				})
				.subscribe((guid: string) => {
					guid = JSON.parse(guid);

					this.activePreview().audioObj = new Audio();
					this.activePreview().audioObj.src = `${environment.contentUrl}Playlists/Preview/${guid}`;
					this.activePreview().audioObj.play();
				});
		}
	}

	private trackActivePreviews(playlist: PbPlaylist | SavedPlaylists): void {
		//if no active previews, add one to the array
		if (this.playlistPreview.length === 0) {
			this.playlistPreview.push({
				isActive: true,
				selectedPlaylist: playlist
			});

			//if toggling off an active preview
		} else if (playlist && this.playlistPreview.length > 0 && this.activePreview().selectedPlaylist.Id === playlist.Id) {
			this.activePreview().audioObj.pause(); //pause preview
			this.activePreview().selectedPlaylist.previewBtnIcon = 'fas fa-play';
			this.activePreview().isActive = false;
			this.playlistPreview.length = 0;

			//if clicking another preview while a current preview is active
		} else if (playlist && this.playlistPreview.length > 0 && this.activePreview().selectedPlaylist.Id !== playlist.Id) {
			this.activePreview().audioObj.pause(); //pause preview
			this.activePreview().selectedPlaylist.previewBtnIcon = 'fas fa-play';
			this.activePreview().isActive = false;
			this.playlistPreview.splice(1, 1, {
				isActive: true,
				selectedPlaylist: playlist
			});
		}
	}

	public activePreview(): PlaylistPreview {
		return this.playlistPreview.find((preview) => preview.isActive);
	}

	private setPreviewBtnProps(
		btnColor: 'blue-bg' | 'pink-bg' | 'yellow-bg',
		btnText: string,
		textColor: 'white-text' | 'dark-text',
		contentFile: ContentFilesVM
	): void {
		contentFile.previewBtnColor = btnColor;
		contentFile.previewBtnText = btnText;
		contentFile.previewBtnTextColor = textColor;
	}

	private setPropsBackToDefault(contentFile: ContentFilesVM): void {
		if (contentFile.VoiceTalent) {
			this.setPreviewBtnProps(
				contentFile.VoiceTalent.TalentGender === 'Female' ? 'pink-bg' : 'blue-bg',
				contentFile.VoiceTalent.TalentName,
				'white-text',
				contentFile
			);
		}
	}
}
