import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { Column } from 'src/app/shared/components/table/view-models';
import { CommonObject, SortParams } from 'src/app/shared/view-models/common-types';
import { ClientSwitcherService, Events, MessageService } from '../../core/services/';
import { AppStateService } from '../../core/services/app-state.service';
import { UtilitiesService } from '../../core/services/utilities.service';
import { Clients } from '../../shared/api-models/admin/clients';
import { PsReportView, ViewState } from './_models';
import { PlayerStatusReportService, TableConfigService } from './_services';

@Component({
	selector: 'psr-player-status-report',
	templateUrl: './player-status-report.component.html',
	styleUrls: ['./player-status-report.component.scss'],
	providers: [TableConfigService]
})
export class PlayerStatusReportComponent implements OnInit, OnDestroy {
	public clients: Clients[];
	public colorKeyVisible: boolean;
	public csrList: CommonObject[];
	public playerStatusReport: PsReportView[];
	public installStates: any[];
	public playerModels: CommonObject[];
	public spinnerActive: boolean;
	public productTypes: CommonObject[] = [
		{ Name: 'All', Id: -1 },
		{ Name: 'Hold', Id: 1 },
		{ Name: 'Radio', Id: 2 },
		{ Name: 'Lobby Video', Id: 3 },
		{ Name: 'Poster', Id: 4 },
		{ Name: '5x1 Array', Id: 6 },
		{ Name: '1x2 Array', Id: 7 }
	];
	private searchTermChanged: Subject<string> = new Subject<string>();
	public selectedClient: any;
	public selectedCsr: any;
	public toggleBtnState: string = 'client';

	private subs: Subscription[] = [];
	private ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private appStateService: AppStateService,
		private clientSwitcherService: ClientSwitcherService,
		private messageService: MessageService,
		public playerStatusReportService: PlayerStatusReportService,
		public tableConfigService: TableConfigService,
		public utilService: UtilitiesService
	) {
		this.onColumnSortSubscribe();
		this.searchTermChanged.pipe(debounceTime(300), takeUntil(this.ngUnsubscribe)).subscribe((val) => {
			this.playerStatusReportService.onClientNameSearch(val).subscribe((clients: Clients[]) => {
				this.clients = clients;
			});
		});
	}

	ngOnInit() {
		this.playerStatusReportService.viewState = new ViewState(this.appStateService.currentClient.Id, 1, -1, -1, 'client', -1, 'LastCheckin', true);
		this.getInstallStates();
		this.getPlayerModels();
		this.getPlayerStatusData();
	}

	onColumnSortSubscribe(): void {
		this.subs.push(
			this.messageService.subscribe(Events.onColumnSort, (sortParams: SortParams) => {
				const { key } = sortParams;
				this.playerStatusReportService.viewState.orderByCol = key;
				sortParams.order === 'asc' ? (this.playerStatusReportService.viewState.orderDesc = false) : (this.playerStatusReportService.viewState.orderDesc = true);
				this.getPlayerStatusData();
			})
		);
	}

	private getPlayerStatusData(stringId?: string): void {
		this.spinnerActive = true;
		this.playerStatusReportService.getPlayerStatusData(stringId).subscribe((playerStatusReport: PsReportView[]) => {
			this.playerStatusReport = playerStatusReport;
			this.spinnerActive = false;
		});
	}

	currentTablePage(pageNumber: number): void {
		this.playerStatusReportService.viewState.pageNumber = pageNumber;
		this.utilService.scrollToTop();
		setTimeout(() => this.getPlayerStatusData(), 500);
	}

	onDropdownSelect(e: CommonObject, stringId: string): void {
		this.playerStatusReportService.viewState[stringId] = e.Id;
		this.playerStatusReportService.viewState.pageNumber = 1;
		this.getPlayerStatusData(stringId);
	}

	onClientOrLocationClick(payload: [PsReportView, Column]): void {
		const [report, column] = [...payload];

		switch (column.key) {
			case 'ClientName':
				localStorage.setItem('changeClientUrl', `/crm/client-details/locations/`);
				window.open(`/my-products/${report._clientId}`);
				break;

			case 'LocationName':
				localStorage.setItem('changeClientUrl', `/crm/client-details/locations/location-details`);
				this.appStateService.setSessionItem('locationId', JSON.stringify(report._locationId));
				this.clientSwitcherService.onClientChange({ CsrId: null, Id: report._clientId, Name: null });
				break;
		}
	}

	public onShowDemoAccountsClick(): void {
		this.playerStatusReportService.viewState.includeDemoAccounts = !this.playerStatusReportService.viewState.includeDemoAccounts;
		this.getPlayerStatusData();
	}

	public onShowPoorNetworkOnlyClick(): void {
		this.playerStatusReportService.viewState.showPoorNetworkOnly = !this.playerStatusReportService.viewState.showPoorNetworkOnly;
		this.getPlayerStatusData();
	}

	public onToggleBtnClick(btnState: string): void {
		this.playerStatusReportService.viewState.toggleBtnState = btnState;
		if (this.playerStatusReportService.viewState.toggleBtnState === 'csr') {
			this.playerStatusReportService.viewState.csrId = -1;
			this.getAllCsrs();
		}
		this.getPlayerStatusData();
	}

	onClientSearch(searchTerm: string): void {
		this.searchTermChanged.next(searchTerm);
	}

	private getAllCsrs(): void {
		this.playerStatusReportService.getAllCsrs().subscribe((csrList: CommonObject[]) => {
			this.csrList = csrList;
		});
	}

	private getInstallStates(): void {
		this.playerStatusReportService.getInstallStates().subscribe((installStates) => {
			this.installStates = installStates;
		});
	}

	private getPlayerModels(): void {
		this.playerStatusReportService.getPlayerModels().subscribe((playerModels: CommonObject[]) => {
			this.playerModels = playerModels;
		});
	}

	ngOnDestroy() {
		this.subs.forEach((sub) => sub.unsubscribe());
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
