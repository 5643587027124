import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService, UtilitiesService } from '../../../../core/services';

@Component({
	selector: 'hd-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
	@Input() animation: string;
	@Input() fontSize: string;
	@Input() menu: any[];
	@Input() setRight: string;
	@Input() setTop: string;
	@Input() wrapperId: string;

	@Output() mouseOverNotify: EventEmitter<any> = new EventEmitter<any>();
	@Output() helpMenuVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() toolsMenuVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private appStateService: AppStateService, private router: Router, public utilService: UtilitiesService) {}

	ngOnInit() {}

	public onMenuItemClick(menuItem: any): void {
		this.helpMenuVisibleChange.emit(false);
		if (menuItem.name === 'Sign out') {
			this.onLogoutClick();
			return;
		}
		if (menuItem.route) {
			this.router.navigate([menuItem.route]);
		}
	}

	private onLogoutClick(): void {
		this.appStateService.removeSessionItem('currentUser');
		sessionStorage.removeItem('authToken');
		sessionStorage.removeItem('validPin');
		this.router.navigate(['/user-sign-out']);
	}

	public onMouseOver(menuItem: any): void {
		this.mouseOverNotify.emit(menuItem);
	}

	public onTooltipClick(event): void {
		this.toolsMenuVisibleChange.emit(false);
		event.stopPropagation();
	}
}
