import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { CommonObject } from 'src/app/shared/view-models/common-types';
import { environment } from '../../../../environments/environment';
import { DateTimeService } from '../../../core/services';
import { UtilitiesService } from '../../../core/services/utilities.service';
import { Clients, PlayerInstallStates, PlayerModels, Users } from '../../../shared/api-models/admin';
import { DeliveryQueue } from '../../../shared/api-models/delivery';
import { PsReportView, ViewState } from '../_models';

@Injectable({
	providedIn: 'root'
})
export class PlayerStatusReportService {
	public totalItems: number;
	public viewState: ViewState;

	constructor(private dateTimeService: DateTimeService, private httpClient: HttpClient, private utilService: UtilitiesService) {}

	public getAllCsrs(): Observable<CommonObject[]> {
		return this.httpClient.get(environment.adminUrl + `CoreUsers/ActiveCsrs`).pipe(
			map((csrList: Users[]) => {
				const tempCsrList = csrList.map((csr) => {
					return {
						Name: `${csr.ContactInformation.FirstName} ${csr.ContactInformation.LastName}`,
						Id: csr.Id
					};
				});
				tempCsrList.unshift({ Name: 'All', Id: -1 });
				return this.utilService.sortItems(tempCsrList, 'Name');
			})
		);
	}

	public getInstallStates(): Observable<CommonObject[]> {
		return this.httpClient.get(environment.adminUrl + `CorePlayerInstallStates`).pipe(
			map((installStates: PlayerInstallStates[]) => {
				installStates.unshift({ Name: 'All', Id: -1 });
				return this.utilService.sortItems(this.assignDropdownView(installStates), 'Name');
			})
		);
	}

	public getPlayerModels(): Observable<PlayerModels[] & CommonObject[]> {
		return this.httpClient.get(environment.adminUrl + `CorePlayerModels`).pipe(
			map((playerModels: PlayerModels[]) => {
				//Filter Dialup, VOIP, and Licence Only players
				const filteredModels: PlayerModels[] & CommonObject[] = playerModels.filter((playerModel) => [1, 2, 4].indexOf(playerModel.PlayerModelTypeId) === -1);
				filteredModels.unshift({ Name: 'All', Id: -1 });
				return this.utilService.sortItems(this.assignDropdownView(filteredModels), 'Name');
			})
		);
	}

	private assignDropdownView<T extends CommonObject>(response: T[]): CommonObject[] {
		return response.map((item) => {
			return {
				Name: item.Name,
				Id: item.Id
			};
		});
	}

	public getPlayerStatusData(stringId?: string): Observable<PsReportView[]> {
		return this.httpClient.get(this.reportUrl(stringId), { observe: 'response' }).pipe(
			concatMap((rawResponse: HttpResponse<PsReportView[]>) => {
				const playerStatusReport: PsReportView[] = rawResponse.body;
				this.totalItems = parseInt(rawResponse.headers.get('x-result-count'));
				if (playerStatusReport.length === 0) {
					return of([]);
				}

				const scheduledPlaylists$ = playerStatusReport.map((report) => {
					return this.httpClient.get<DeliveryQueue[]>(`${environment.deliveryUrl}DeliveryQueue/ScheduledPlaylists/${report._playerId}`);
				});

				return forkJoin(scheduledPlaylists$).pipe(
					map((queueArr: DeliveryQueue[][]) => {
						return this.playerStatusView(playerStatusReport, queueArr);
					})
				);
			})
		);
	}

	public onClientNameSearch(val: string): Observable<Clients[]> {
		return this.httpClient.get(environment.adminUrl + 'CoreClients/NameSearch/' + val).pipe(
			map((clients: Clients[]) => {
				return clients.map((client) => {
					client.name = client.Name;
					return client;
				});
			})
		);
	}

	public reportUrl(stringId?: string): string {
		if (stringId === 'csrId' || this.viewState.toggleBtnState === 'csr') {
			return `${environment.adminUrl}Reports/PlayerStatus?csrId=${this.viewState.csrId}&playerModelId=${this.viewState.playerModelId}&playerInstallState=${this.viewState.installStateId}&productTypeId=${this.viewState.productTypeId}&orderByCol=${this.viewState.orderByCol}&orderDesc=${this.viewState.orderDesc}&includeDemoAccounts=${this.viewState.includeDemoAccounts}&onlyPoorNetworkConditions=${this.viewState.showPoorNetworkOnly}&pageNumber=${this.viewState.pageNumber}&pageSize=500`;
		} else {
			return `${environment.adminUrl}Reports/PlayerStatus?clientId=${this.viewState.clientId}&playerModelId=${this.viewState.playerModelId}&playerInstallState=${this.viewState.installStateId}&productTypeId=${this.viewState.productTypeId}&orderByCol=${this.viewState.orderByCol}&orderDesc=${this.viewState.orderDesc}&includeDemoAccounts=${this.viewState.includeDemoAccounts}&onlyPoorNetworkConditions=${this.viewState.showPoorNetworkOnly}&pageNumber=${this.viewState.pageNumber}&pageSize=500`;
		}
	}

	private playerStatusView(playerStatusReportArr: PsReportView[], queueArr: DeliveryQueue[][]): PsReportView[] {
		return playerStatusReportArr.map((playerStatusReport, i) => {
			playerStatusReport.lastCheckinView = this.checkedInView(playerStatusReport, queueArr[i]);
			playerStatusReport.lastContentUpdateView = this.dateTimeService.dateAndTimeUTCtoLocal(playerStatusReport.LastUpdate);
			playerStatusReport.classList = 't-row';
			playerStatusReport.rowBgColor = this.setRowBgColor(playerStatusReport);
			playerStatusReport.repairStateView = this.setRepairStateIcon(playerStatusReport);

			const checkinTooltipNum: number = this.dateTimeService.dateDifferenceInDays(new Date(playerStatusReport.LastCheckin), new Date());
			const lastUpdateTooltipNum: number = this.dateTimeService.dateDifferenceInDays(new Date(playerStatusReport.LastUpdate), new Date());
			playerStatusReport.checkinTooltip = `${checkinTooltipNum} ${checkinTooltipNum === 1 ? 'day' : 'days'}`;
			playerStatusReport.lastUpdateTooltip = `${lastUpdateTooltipNum} ${lastUpdateTooltipNum === 1 ? 'day' : 'days'}`;
			playerStatusReport.networkView = playerStatusReport._poorNetworkConditions ? 'fas fa-times' : 'fas fa-check';

			if (JSON.parse(playerStatusReport.ActivePlaylist) && JSON.parse(playerStatusReport.ActivePlaylist).length) {
				playerStatusReport.activePlaylistName = JSON.parse(playerStatusReport.ActivePlaylist)[0].PlaylistName;
			}
			return playerStatusReport;
		});
	}

	private checkedInView(playerStatusReport: PsReportView, queue: DeliveryQueue[]): string {
		//Add error icon if playlist start date is in the past
		const atLeastOneUndownloadedPlaylist = queue.some((q) => this.dateTimeService.todayIsAfter(q.StartDate));
		return atLeastOneUndownloadedPlaylist
			? `${this.dateTimeService.dateAndTimeUTCtoLocal(playerStatusReport.LastCheckin)} <i class="fas fa-exclamation-triangle red-text ft-size14"></i>`
			: this.dateTimeService.dateAndTimeUTCtoLocal(playerStatusReport.LastCheckin);
	}

	private setRepairStateIcon(playerStatusReport: PsReportView): string {
		switch (playerStatusReport.Repair) {
			case 'Ok':
				return 'fas fa-check';
			case 'Idle':
				return 'far fa-clock';
			case 'Being Repaired':
				return 'fas fa-wrench';
			case 'Pending Completion':
				return 'fas fa-hourglass-half';
			case 'Fickle':
				return 'fas fa-toggle-off';
		}
	}

	private setRowBgColor(playerStatusReport: PsReportView): string {
		if (playerStatusReport.Repair === 'Being Repaired') {
			return 'yellow-bg';
		}
		if (this.dateTimeService.within24Hours(playerStatusReport.LastCheckin)) {
			return 'delivery-queue-green-bg';
		}
		return 'white-bg';
	}
}
